import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgByID, handleLogout } from "../../slices/thunk";
import React, { useState, useEffect } from "react";
import { Logout, LockReset } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TextField } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { baseURL } from "../../configuration/url";
import LogoutConfirmationModal from "../LogoutModel/index";
import { greytext, primarytext } from "../../common/primary";
import Loader from "../loader/Loader";
import moment from "moment";
import { styled } from '@mui/material/styles';
interface Organization {
  id: string;
  name: string;
}

interface HeaderProps {
  currentPage: string;
  subPage: string;
  isSidebarOpen: boolean;
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" font-family="Arial" font-size="10" fill="darkblue">SOS</text></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#A6C8F4', // Dark blue for the header contrast
        ...theme.applyStyles('dark', {
          backgroundColor: '#002244',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff', // White thumb
    width: 30,
    height: 30,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" font-family="Arial" font-size="10" fill="darkblue">Q15</text></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#A6C8F4', // Matches header color
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#002244',
    }),
  },
}));


const Header: React.FC<HeaderProps> = ({ currentPage, subPage, isSidebarOpen }) => {
  const dispatch = useDispatch<any>()
  const [orgName, setOrgName] = useState("");
  const [organizationData, setOrganizationData] = useState<Organization[]>([]);
  const { orgName: lgOrgName, currentDateTime, orgTimeZone } = useSelector((state: any) => state.LargeScreen)
  const { shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
  const { useDetailProfile } = useSelector((state: any) => state.Login)
  const { orgData } = useSelector(
    (state: any) => state.Org
  )
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      navigate('/sos-view');
    } else {
      navigate('/patient-view');
    }
  };
  useEffect(() => {
    if (location.pathname === '/sos-view') {
      setChecked(true);
    } else if (location.pathname === '/patient-view') {
      setChecked(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      // Ensure orgTimeZone is a valid time zone string
      const validTimeZone = orgTimeZone ? orgTimeZone : "America/Detroit";
      try {
        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
          timeZone: validTimeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }).format(now);
        localStorage.setItem('LTime', moment(formattedDateTime, 'MM/DD/YYYY HH:mm:ss a').format('MM/DD/YYYY HH:mm:ss'));
      } catch (error) {
        console.error("Error formatting date/time:", error);
      }
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [orgTimeZone]);


  const fetchOrganizationData = async () => {
    try {
      const response = await fetch(`${baseURL}/org/name`);
      const data = await response.json();
      setOrganizationData(data.data);
    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };

  const organizationId = useSelector((state: any) => state.Login.organization);
  useEffect(() => {
    fetchOrganizationData();
    getOrgByID(dispatch, organizationId)
  }, [organizationId]);
  useEffect(() => {

    if (userType !== "Super Admin") {
      const organization = organizationData.find(org => org.id === userData.organization);
      if (organization) {
        setOrgName(organization.name)
        localStorage.setItem('orgName', organization.name)
      }
    }

  }, [organizationData, organizationId]);

  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");

  const [email, setEmail] = useState("");
  useEffect(() => {
    const given = localStorage.getItem("given");
    const family = localStorage.getItem("family");
    if (given && family) {
      setGivenName(given);
      setFamilyName(family);
    }
  }, []);
  useEffect(() => {
    const userEmail = localStorage.getItem("userDetailEmail");
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const navigate = useNavigate();
  const isLoginPage = location.pathname === "/login";
  const isSecretKeyPage = location.pathname === "/secret-key";
  const isUnblockPage = location.pathname === "/account-unblock";
  const isForgotPassword = location.pathname === "/forgot-password";
  const isChangePassword = location.pathname === "/change-password";
  const isVerifyOTP = location.pathname === "/verify-otp";
  const isResetSecret = location.pathname === "/resetSecretKey";
  const isReCreatePassword = location.pathname === "/recreatePassword1";
  const patientView = location.pathname === "/patient-view";
  const staffView = location.pathname === "/sos-view";
  const OrgDetail = location.pathname === "/organization-details"

  const toggle = patientView || staffView
  const toggleAccess = orgData.sos && orgData?.q15Access === 'Yes'

  const { jwt, userType, userData, loading } = useSelector(
    (state: any) => state.Login
  );

  const [LorgName, setLorgName] = useState('')
  const [Ltime, setLTime] = useState('')
  const [LDate, setLDate] = useState<any>('')
  useEffect(() => {
    const handletime = () => {
      const LorgName: any = localStorage.getItem('orgName');
      const Ltimes: any = localStorage.getItem('LTime');

      setLTime(moment(Ltimes, 'MM/DD/YYYY HH:mm:ss').format('hh:mm:ss A'))
      // Format the Ltime if it exists and ensure it's in "MMM DD - YYYY HH:mm" format
      const formattedLTime = Ltimes
        ?  moment(Ltimes, 'MM/DD/YYYY HH:mm:ss').format('MMM DD, YYYY')
        //  handleDateTime(moment(Ltimes, 'MM/DD/YYYY HH:mm:ss').format('HH:mm'))
        : moment(currentDateTime).format('MMM DD, YYYY');
      setLDate(formattedLTime);

      setLorgName(LorgName ? LorgName : lgOrgName);

    }
    const inter = setInterval(() => { handletime() }, 1000);

  }, [currentDateTime, orgTimeZone, orgName]);

  const username = useSelector((state: any) => state.Login.userDetails);
  const [modal, setModal] = useState(false);

  const showHeader =
    !isLoginPage &&
    !isSecretKeyPage &&
    !isUnblockPage &&
    !isForgotPassword &&
    !isChangePassword &&
    !isVerifyOTP &&
    !isResetSecret &&
    !isReCreatePassword;

  if (!showHeader) {
    return null;
  }
  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleConfirmLogout = () => {
    const body = { jwt, username };
    handleLogout(body, navigate, dispatch);
    localStorage.clear();
    // setOpen(false);
  };


  const handleChangePassword = () => {
    // setOpen(!open);
    navigate("/recreatePassword", { state: location.pathname });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClo = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileCheck = () => {
    setModal(!modal);
  };
  const user = userData.userDetail?.firstName + " " + userData.userDetail?.lastName || "";
  const role = userData.userDetail?.role || "";
  const mobilePhone = userData.userDetail?.contact?.[0]?.mobilePhone || "";
  const dateofBirth = userData.userDetail?.dateofBirth || "";
  const speciality = userData.userDetail?.speciality?.[0] || "";
  const organizationName =
    userData.userDetail?.organizationdetails?.[0]?.name || "";

  return (
    <>
      {loading && <Loader />}
      <div
        className='row mHeader'
      >
        <div
          hidden={OrgDetail ? true : false}
          className="px-5"
          style={{
            color: 'white',
            alignItems: 'center',
            width: '40%',
            display: 'flex',
            justifyContent: 'flex-start',
            zIndex: 101,
            flexDirection: 'row',
            marginLeft: patientView || staffView ? '' : isSidebarOpen ? '210px' : '40px',
            transition: 'width 0.5s ease',
          }}
        >
          {orgData && orgData.logo ? (
            <Avatar
              sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2', marginRight: '10px' }}
              src={orgData.logo}
            >
            </Avatar>
          ) : (
            <Avatar
              sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2', marginRight: '10px' }}
            >
            </Avatar>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <p className="org-name" style={{ margin: 0 }}>
              Organization: {LorgName}
            </p>
            <p className="time" style={{ margin: 0 }}>
              Date/Time: {LDate} {Ltime}
            </p>
          </div>
        </div>
        <div className="header-container" style={{ backgroundColor: '#2a509a', padding: '10px' }}>
          {/* <FormGroup> */}
          {(toggle && toggleAccess) && (
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: 1 }} onChange={handleSwitchChange} checked={checked} />}
              label=""
            />
          )}
        </div>
      </div>
      <div className="avatar-container">
        <Box>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
              >
                {username.charAt(0).toUpperCase()}
              </Avatar>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open1}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfileCheck}>
          <Avatar
            sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
            src={localStorage.getItem("profile") || undefined}
          >
            {localStorage.getItem("profile") ? null : username.charAt(0).toUpperCase()}
          </Avatar>
          {userType === "Super Admin" ? (
            <div style={{ fontSize: '12px' }}>
              <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{user}</p>
              <p style={{ margin: '0px', color: greytext }}>{userType}</p>
              <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{organizationName}</p>
            </div>
          ) : (userType === 'Admin') ? (
            <div style={{ fontSize: '12px' }}>
              <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{givenName} {familyName}</p>
              <p style={{ margin: '0px', color: greytext }}>Supervisor</p>
              <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
            </div>
          ) : (userType === 'System Admin') ? (
            <div style={{ fontSize: '12px' }}>
              <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{username}</p>
              <p style={{ margin: '0px', color: greytext }}>{userType}</p>
              <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
            </div>
          ) : (
            <div style={{ fontSize: '12px' }}>
              <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{givenName} {familyName}</p>
              <p style={{ margin: '0px', color: greytext }}>{userType === "Receptionist-sos" ? "Receptionist-SOS" : userType}</p>
              <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
            </div>
          )}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <LockReset fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>

        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <LogoutConfirmationModal
        open={open}
        handleConfirm={() => handleConfirmLogout()}
        handleClose={handleClo}
        message="Are you sure you want to logout?"
      />
      <Modal
        isOpen={modal}
        toggle={() => setModal(false)}
        centered
        style={{ fontFamily: "calibri", fontSize: "20px" }}
      >
        <ModalHeader toggle={() => setModal(false)}>
          {" "}
          User Profile Details
        </ModalHeader>
        <ModalBody>
          {userType === "Super Admin" ? (
            <>
              <div className="row">
                <div className="col-md-6"> <TextField
                  id="outlined-basic-1"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={useDetailProfile?.username}
                /></div>
                <div className="col-md-6"><TextField
                  id="outlined-basic-2"
                  label="User Type"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={useDetailProfile?.userType}
                /></div>
                <div className="col-md-6"><TextField
                  id="outlined-basic-2"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={useDetailProfile?.firstName}
                /></div>
                <div className="col-md-6"><TextField
                  id="outlined-basic-2"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={useDetailProfile?.lastName}
                /></div>
              </div>
              <div className="row">
                <div className="col-md-12"><TextField
                  id="outlined-basic-3"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={useDetailProfile?.email}
                />
                </div>
              </div>
              <div className="row">
              </div>
            </>
          ) : (userType === 'Admin' || userType === 'Staff') ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-4"
                    label="Organization Name"
                    variant="outlined"
                    value={orgName}
                    fullWidth
                    style={{ marginBottom: "20px" }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-1"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={username}
                  /></div>
              </div>
              <div className="row ">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-2"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={givenName}
                  /></div>
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-2"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={familyName}
                  /></div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <TextField
                    id="outlined-basic-3"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={email}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-5"
                    label="Role"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={role}
                  /></div>
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-9"
                    label="Speciality"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={speciality}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-6"
                    label="Mobile No"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={mobilePhone}
                  /></div>
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic-10"
                    label="Date Of Birth"
                    value={dateofBirth}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6"><TextField
                  id="outlined-basic-1"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={username}
                /></div>
                <div className="col-md-6"><TextField
                  id="outlined-basic-2"
                  label="User Type"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={userType}
                /></div>

              </div>
              <div className="row ">
                <div className="col-md-12"><TextField
                  id="outlined-basic-3"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  value={email}
                /></div>

              </div>
              <div className="row">
                <div className="col-md-12"><TextField
                  id="outlined-basic-4"
                  label="Organization Name"
                  variant="outlined"
                  value={orgName}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                /></div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Header;