import axios from 'axios';
import { isLoading, setErrorMessage, setIsLoadingFalse, getPatientSuccess, getPatientgetOrgSuccess, getTotalElementsSuccess, getPatientgetAllOrgSuccess, getPatientDropdownSuccess,getInPatientDropdownSuccess,getDeviceDropdownSuccess, setNewPatientData, setPatientAndBedAssign } from './reducer';
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllPatient = async (dispatch: any,org: string, pageNo: number, search: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/patient/get/activePatient/${org}?page=${pageNo}&search=${search}&pageSize=10`,{headers: header1});

    if (response.data.message.code === successCode) {
      if (pageNo === -1) {
        dispatch(getPatientSuccess(response.data.data));
      } else {
        dispatch(getPatientSuccess(response.data.data.content));
      }
      dispatch(setIsLoadingFalse());
      dispatch(getTotalElementsSuccess(response.data.data.totalElements))
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(getPatientSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};
export const getOrgPatient = async (dispatch: any,org: string, pageNo:any, search: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/patient/getPatient/org/${org}?page=${pageNo}&search=${search}&pageSize=10`,{headers: header1});

    if (response.data.message.code === successCode) {
      dispatch(getPatientgetOrgSuccess(response.data.data.content));
      dispatch(getTotalElementsSuccess(response.data.data.totalElements))
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(getPatientgetOrgSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};
export const getAllOrgPatient = async (dispatch: any,org: string, pageNo:any, search: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/patient/getAllPatient/org/${org}?page=${pageNo}&search=${search}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(getPatientgetAllOrgSuccess(response.data.data.content));
      dispatch(getTotalElementsSuccess(response.data.data.totalElements))
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(getPatientgetAllOrgSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};

export const updatePatientDetails = (id: string, data: any, org: string,currentPage:number) => async (
  dispatch: any
) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.put(`${baseURL}/patient/update/${id}`, data, {headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      toast.success(response.data.message.description)
      getAllOrgPatient(dispatch, org, currentPage, '')
      getOrgPatient(dispatch, org, currentPage,'')
      getAllPatient(dispatch, org, currentPage, '');
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(setIsLoadingFalse());
      toast.error(response.data.message.description)
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    toast.error("Error: something went wrong.")
  }
};

export const patientDischarge = async (dispatch: any,id:string,org:string,currentPage:number) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.post(`${baseURL}/Q15Bed/dischargePatient/${id}/${org}`,{headers: header1});
    if (response.data.message.code === successCode) {
      toast.success(response.data.message.description)
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      getOrgPatient(dispatch,org,currentPage, '')
      getAllPatient(dispatch, org, currentPage, '');
      dispatch(setIsLoadingFalse());
      toast.error(response.data.message.description)
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    toast.error("Error: something went wrong.")
  }
};

export const getPatientDropdown = async (dispatch: any,org: string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/patient/getPatientDropdown?organization=${org}`,{headers: header1});

    if (response.data.message.code === successCode) {
      dispatch(getPatientDropdownSuccess(response.data.data));
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(getPatientDropdownSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};
export const getInPatientDropdown = async (dispatch: any,org: string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/patient/getInactivePatientDropdown?organization=${org}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(getInPatientDropdownSuccess(response.data.data));
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      dispatch(getInPatientDropdownSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};
export const getDeviceDropdown = async (dispatch: any,org: string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    
    const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon?organization=${org}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(getDeviceDropdownSuccess(response.data.data));
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(getDeviceDropdownSuccess([]));
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error(error);
  }
};

export  const fetchNewPatientData = async (dispatch: any, organization: string) => {
  try {
    const response = await axios.get(
      `${baseURL}/patient/get/activePatientMob/${organization}`
    );

    if (response.data.data && Array.isArray(response.data.data)) {
      dispatch(setNewPatientData(response.data.data))
    } else {
      console.error("Invalid data format for patients:", response.data);
      dispatch(setNewPatientData([]))
    }
  } catch (error) {
    dispatch(setNewPatientData([]))
    console.warn(error);
  }
}

export const fetchPatientsandBedAssign = async (dispatch: any, organization: string) => {
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        dispatch(setPatientAndBedAssign(response.data.data));
      } else {
        dispatch(setPatientAndBedAssign([]));
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      dispatch(setPatientAndBedAssign([]));
      console.warn(error);
    }
  };