import React, { useState } from "react";
import Image2 from '../../assets/images/image2.png';
import mettler from '../../assets/images/mettler.jpg';
import { InputAdornment, TextField } from "@mui/material";
import { Button } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Email } from "@mui/icons-material";
import { toast } from "react-toastify";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import Loader from "../../components/loader/Loader";
interface Data {
  email: string;
}
const ForgotPassword = () => {
  const [data, setData] = useState<Data>({
    email: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})

  const handleRequest = async () => {
    const { header1 } = authorize();
    let newErrors = {
      email: !data.email
    }
    setNameError(newErrors)

    const hasErrors = Object.values(newErrors).some(error => error);
    if (hasErrors) {
      toast.error("Email address is required.")
      return;
    }
    try {
      setLoading(true)
      const response = await axios.post(`${baseURL}/user/forgot-password`, data, { headers: header1 });
      if (response.data.message && response.data.message.code === successCode) {
        setLoading(false)
        toast.success(response.data.message.description);
        localStorage.setItem('savedEmail', data.email);
        navigate('/verify-otp');
      } else{
        setLoading(false)
        toast.error(response.data.message.description)
      }
    } catch (error: any) {
      setLoading(false)
      if (error.response) {
        console.warn('Server Error:', error.response.data);
        if (error.response.data && error.response.data.message) {
          toast.error(`Error: ${error.response.data.message.description}`);
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      } else if (error.request) {
        console.warn('Request Error:', error.request);
        toast.error('Network issue. Please try again.');
      } else {
        console.warn('Error:', error);
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };
  return (
    <div className="row w-100 h-100" >
      {loading && <Loader/>}
      <div className="col-md-7 ">
        <img src={Image2} alt="Image"
          className="img-fluid"
          style={{ objectFit: "cover", height: '100vh' }}></img>
      </div>
      <div className="col-md-5 d-flex flex-column align-items-md-center justify-content-md-center">
        <div className="d-flex justify-content-center mb-2">
          <img className="p-0 "
            src={mettler}
            style={{ height: "-webkit-fill-available" }}
            alt="Image"
          ></img>
        </div>
        <form className="rounded col-md-8" style={{ padding: '30px' }} >
          <div className="d-flex flex-column">
            <label className="text-start" style={{ width: '233px', height: '32px', fontSize: '21px', color: '#2E1B1B' }}>Forgot your password</label>
            <label className="text-start mb-5" style={{ width: '286px', height: '20px', fontSize: '13px', color: '#545B82' }}>We’ll help you reset it and get back on track.</label>
            <TextField
              id="outlined-basic-1"
              label="Email"
              variant="outlined"
              fullWidth
              value={data.email}
              onChange={(e) => {setData({ ...data, email: e.target.value }); setNameError({ ...nameError, email: false })}}
              InputProps={{ startAdornment: (<InputAdornment position="start"><Email style={{ color: '#9F9FA2' }} /></InputAdornment>) }}
              error={!!nameError.email}
            />
            <Button className="mb-3 mt-3" color="primary" style={{ fontSize: '15px', height: '56px' }} onClick={handleRequest}>
              Click to Send OTP
            </Button>
            <div className="d-flex align-items-center justify-content-center">
              <p style={{ cursor: 'pointer', color: '#2D70F4' }} onClick={() => navigate(-1)}>Back to Login</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;