import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getStaffSuccess, getTotalElementsSuccess, getStaffLSSuccess, getStaffDropdownSuccess, getStaff1DropdownSuccess, setgenderList, setSpecialityList, setRolesList, getStaffDropDown1to1, getStaffDropDownEyeSight } from "./reducer"
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllStaff = async (dispatch: any,organization:string, page: number, search: any) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/staff/get/ActiveStaff/${organization}?page=${page}&search=${search}`,{headers: header1});
      if (response.data.message.code === successCode) {
        dispatch(setIsLoadingFalse());
        if (page === -1) {
          dispatch(getStaffSuccess(response.data.data));
        } else {
          dispatch(getStaffSuccess(response.data.data.content));
        }
        dispatch(getTotalElementsSuccess(response.data.data.totalElements));
      } else {
        dispatch(getStaffSuccess([]));
      dispatch(setIsLoadingFalse());
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
    }
  };

  export const getAllStaffLS = async (dispatch: any, date:string, organization: string) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/staff/getAllActiveStaffForLargeScreen?organization=${organization}`,{headers: header1});


      if (response.data.message.code === successCode) {
        dispatch(getStaffLSSuccess(response.data.data))
      } else {
        dispatch(getStaffLSSuccess([]))
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
    }
  }

export const deleteStaffDetails=(username:string,organization:string, currentPage: number)=>async(dispatch:any)=>{
  dispatch(isLoading());
  const { header1 } = authorize();
try{
  const response=await axios.delete(`${baseURL}/staff/delete/${username}`,{headers: header1});
  if(response.data.message.code===successCode){
    getAllStaff(dispatch,organization,currentPage, '')
    dispatch(setIsLoadingFalse());
    toast.success(response.data.message.description)
  }else{
    dispatch(setIsLoadingFalse());
    toast.error(response.data.message.description)
  }
  }catch(error){
  dispatch(setIsLoadingFalse())
  toast.error("Error: something went wrong.")
}
}

export const createStaff = (requestBody: any, handleCancel: any,org:any,currentPage:number) => async (dispatch: any) => {
  dispatch(isLoading())
  const { header1 } = authorize();
  try {
    const response = await axios.post(baseURL + '/staff/register', requestBody,{headers: header1})
    if (response.data.message.code === successCode) {
      toast.success(response.data.message.description)
      dispatch(setIsLoadingFalse())
      getAllStaff(dispatch,org,currentPage, '')
      handleCancel()
    } else {
      dispatch(setIsLoadingFalse())
      toast.error(response.data.message.description)
    }
  } catch (error) {
    dispatch(setIsLoadingFalse())
  }
}

export const updateStaffDetails = (id: any, data: any, organization:string, setEditModal: any,currentPage:number) => async (
  dispatch: any
) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.put(`${baseURL}/staff/update/${id}`, data,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      toast.success(response.data.message.description)
      setEditModal(false)
      getAllStaff(dispatch,organization,currentPage, '');
    } else {
      dispatch(setIsLoadingFalse());
      dispatch(setErrorMessage(response.data.message.description));
      toast.error(response.data.message.description);
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    toast.error("Error: something went wrong.")
  }
};

export const getStaffDropdown = async (dispatch: any,organization:string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/staff/getStaffDropdown?organization=${organization}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      dispatch(getStaffDropdownSuccess(response.data.data));
    } else {
      dispatch(getStaffDropdownSuccess(response.data.data));
    dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
  }
};
export const getStaffDropdown1 = async (dispatch: any,organization:string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/staff/getStaffRoleBasedDropdown?organization=${organization}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      dispatch(getStaff1DropdownSuccess(response.data.data));
    } else {
      dispatch(getStaff1DropdownSuccess(response.data.data));
    dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
  }
};

export const fetchStaffDropdownData = async (dispatch: any, organization: string) => {
      const { header1 } = authorize()
      try {
        const response1 = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Gender&organizationId=${organization}&page=-1`, { headers: header1 })
        const response2 = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Speciality&organizationId=${organization}&page=-1`, { headers: header1 })
        const response3 = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Staff Roles&organizationId=${organization}&page=-1`, { headers: header1 })

        if (response1.data.message.code === successCode) {
          dispatch(setgenderList(response1.data.data[0].list))
        } else {
          dispatch(setgenderList([]))
        }
        if (response2.data.message.code === successCode) {
          dispatch(setSpecialityList(response2.data.data[0].list))
        } else {
          dispatch(setSpecialityList([]))
        }
        if (response3.data.message.code === successCode) {
          dispatch(setRolesList(response3.data.data[0].list))
        } else {
          dispatch(setRolesList([]))
        }
      } catch (error) {
        console.warn(error, 'checking the error')
      }
    }
    
    export const fetchStaffDropdownData1to1 = async (dispatch: any, date: any, org: any, shift: any) => {
      const { header1 } = authorize()
      dispatch(isLoading());
      try {
        const response = await axios.get(`${baseURL}/getStaff/getStaffForOneToOne?date=${date}&organization=${org}&shiftName=${shift}`, { headers: header1 })
        if (response.data.message.code === successCode) {
          dispatch(setIsLoadingFalse());
          dispatch(getStaffDropDown1to1(response.data.data))
        } else {
          dispatch(setIsLoadingFalse());
          dispatch(getStaffDropDown1to1([]))
        }
      } catch (error:any) {
        dispatch(setIsLoadingFalse());
        console.error("API Error: ", error)
      }
    }

    export const fetchStaffDropdownDataEyeSight = async (dispatch: any, date: any, org: any, shift: any) => {
      const { header1 } = authorize()
      dispatch(isLoading());
      try {
        const response = await axios.get(`${baseURL}/getStaff/getStaffForEyeSight?date=${date}&organization=${org}&shiftName=${shift}`, { headers: header1 })
        if (response.data.message.code === successCode) {
          dispatch(setIsLoadingFalse());
          dispatch(getStaffDropDownEyeSight(response.data.data))
        } else {
          dispatch(setIsLoadingFalse());
          dispatch(getStaffDropDownEyeSight([]))
        }
      } catch(error: any) {
        dispatch(setIsLoadingFalse());
        console.error("API Error:", error)
      } 
    }