import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  staffData: [],
  staffDropdown: [],
  staffDropdown1: [],
  genderList: [],
  specialityList: [],
  rolesList: [],
  totalElements: 0,
  isOpen: false,
  errorMsg: "",
  isSuccess: false,
  staffData1: [],
  StaffDropDown1to1: [],
  staffDropDownEyeSight: []
};

const StaffCreationSlice = createSlice({
  name: "staffData",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },

    setIsLoadingFalse(state) {
      state.loading = false;
    },
    setErrorMessage(state, action) {
      state.loading = false;
      state.isOpen = true;
      state.errorMsg = action.payload;
    },
    setSuccess(state) {
      state.isSuccess = true;
    },
    resetSuccess(state) {
      state.isSuccess = false;
    },

    closeErrorPopup(state) {
      state.isOpen = false;
      state.errorMsg = "";
    },
    setRolesList (state, action) {
      state.rolesList = action.payload
    },
    setSpecialityList (state, action) {
      state.specialityList = action.payload
    },
    setgenderList (state, action) {
      state.genderList = action.payload
    },

    setAllSetupOptions (state, action) {
      const { Gender, Speciality, Roles } = action.payload
      state.loading = false
      state.genderList = Gender
      state.specialityList = Speciality
      state.rolesList = Roles
    },
    getStaffSuccess(state, action) {
      state.loading = false;
      state.staffData = action.payload;
    },
    getStaffDropdownSuccess(state, action) {
      state.loading = false;
      state.staffDropdown = action.payload;
    },
    getStaff1DropdownSuccess(state, action) {
      state.loading = false;
      state.staffDropdown1 = action.payload;
    },
    getStaffLSSuccess(state, action) {
      state.loading = false;
      state.staffData1 = action.payload;
    },

    getTotalElementsSuccess(state, action) {
      state.totalElements = action.payload;
    }, 
    getStaffDropDown1to1 (state, action) {
      state.StaffDropDown1to1 = action.payload
    },
    getStaffDropDownEyeSight (state, action) {
      state.staffDropDownEyeSight = action.payload
    }
  },
});

export const {
  isLoading,
  setIsLoadingFalse,
  setErrorMessage,
  closeErrorPopup,
  getStaffSuccess,
  getTotalElementsSuccess,
  setSuccess,
  resetSuccess,
  setgenderList,
  setSpecialityList,
  setRolesList,
  setAllSetupOptions,
  getStaffLSSuccess,
  getStaffDropdownSuccess,
  getStaff1DropdownSuccess,
  getStaffDropDown1to1,
  getStaffDropDownEyeSight
} = StaffCreationSlice.actions;

export default StaffCreationSlice.reducer;