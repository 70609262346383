import React, { useEffect, useState } from 'react'
import ReportView from './ReportView'
import { useDispatch, useSelector } from 'react-redux'
import { baseURL, successCode } from '../../configuration/url'
import axios from 'axios'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import moment from 'moment'
import './Reports.css'
import { primarybg, primarytext, secondarybt } from '../../common/primary'
import html2canvas from 'html2canvas'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import jsPDF from 'jspdf'
import { getActivityLegend, getLocationLegend } from '../../slices/Reports/thunk'
import { setActivityLegend, setLocationLegend } from '../../slices/Reports/reducer'
interface EyeSightPatientReportViewProps {
    EyesightReportData: any
    setLoading: any
    checkBox: any
    legendcontrol: any
}

const EyeSightPatientReportView: React.FC<EyeSightPatientReportViewProps> = ({ EyesightReportData, setLoading, checkBox, legendcontrol }) => {
    const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
    const org = useSelector((state: any) => state.Login.organization)
    const [eyeSightdata, setEyesightData] = useState<any>([])
    const [OrgName, setOrgName] = useState<any>('')
    const [expanded, setExpanded] = useState<string | false>('panel1');

    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const dispatch = useDispatch<any>()

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (EyesightReportData !== null && EyesightReportData.length > 0) {
            const filterData = EyesightReportData && EyesightReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime)
            setEyesightData(filterData)
        } else {
            setEyesightData([])
        }
    }, [EyesightReportData])


    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
        return () => {
            dispatch(setActivityLegend([]))
            dispatch(setLocationLegend([]))
        }
    }, [org])


    const handleSingltDownload = async (str: any) => {
        if (eyeSightdata) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < eyeSightdata.length) {
                        const currentIndex = index;
                        setTimeout(() => {
                            if (moment(eyeSightdata[currentIndex].date).format("DD-MM-YYYY") === str) {
                                timeDealys(eyeSightdata[currentIndex]);
                            }
                        }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);
                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    return (
        <div >
            {
                eyeSightdata !== null && eyeSightdata !== undefined && eyeSightdata.length > 0 && eyeSightdata?.map((data: any, inx: number) => (
                    <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)} style={{ width: '100%' }}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: expanded === 'panelmain' + data.date ? secondarybt : '' }} expandIcon={<ArrowDropDownIcon />}>
                            <Typography >
                                <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                                    <div className='' style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                        {moment(data.date).format("MMM DD, YYYY")}
                                    </div>
                                </div>
                            </Typography>
                            <div>
                                <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                                    handleSingltDownload(moment(data.date).format("DD-MM-YYYY"))
                                }}>Download</button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }} >
                                <div className='container-fluid'>
                                    {/* Report Time Am View Start */}
                                    <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                                        {data && <>
                                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                                {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                                {/* {orgData && orgData.logo ? (
                              <Avatar
                                sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                src={orgData.logo}
                              >
                              </Avatar>) : <></>} */}
                                            </div>
                                            <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                                                {OrgName && OrgName}
                                            </div>
                                            {/* <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="p-col-12 p-md-12">
                                                              Mettler Health Care Organization
                                                            </div> */}
                                            <div className='d-flex justify-content-between'>
                                                <div className='col-8'>
                                                    <div className='d-flex flex-row gap-3'>
                                                        <div><strong>Date: </strong>
                                                            {data.date && moment(data.date).format("MMM DD, YYYY")}
                                                            {/* {selectShiftView ? selectShift === "Shift-C" && `, ${nextDate}` : ''} */}
                                                        </div>
                                                        {/* {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>} */}
                                                    </div>
                                                    <strong>Patient Name: </strong>{data.patientId}
                                                </div>
                                                <div className='d-flex justify-content-between col-4'>
                                                    <div>
                                                        <strong>Room No: </strong>
                                                        {data.assignedBed && data.assignedBed.split('-')[0]}
                                                    </div>
                                                    <div>
                                                        <strong className='pe-3'>AssignedBed:</strong>
                                                        {data.assignedBed && data.assignedBed.split('-')[1]}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: '1px solid #C9C9C9' }}>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                    <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                        <div>
                                                            CHECK PRECAUTIONS:
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Every 15 Minutes
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                1:1
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Eyesight
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Agitated Behavior
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Arson
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Assault
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Fall
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Detox
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Elopement
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Homicide
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Sexual Acting Out
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Self Harm
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Other {`${'('}`}Describe{`${'):'}`}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                    <div>LOCATION CODES</div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                        {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                            <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                    {item.key}-{item.value}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                    <div>ACTIVITY CODES</div>
                                                </div>
                                                <div>
                                                    {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                          <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                              {inx + 1}.{item.value}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </div> */}
                                                    <div className='d-flex flex-row flex-wrap py-1'
                                                    //  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                                    >
                                                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                            <div className="col-3 px-1 " style={{ height: '30px' }} title={item.value} key={inx}>
                                                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                    {inx + 1}.{item.value}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='' style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                                <div style={{ width: '50%', fontSize: '14px' }}>
                                                    {data && data.configResponses?.map((t: any, ind: number) => {
                                                        return ind >= 0 && ind <= 23 && (<>
                                                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                                <div style={{ width: '50%', fontSize: '14px' }}>
                                                    {data && data.configResponses?.map((t: any, ind: number) => {
                                                        return ind >= 24 && ind <= 47 && (<>
                                                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                            </div>

                                            <div style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                </div>
                                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                                    <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                                    <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                                </div>
                                            </div>

                                        </>}
                                    </section>
                                    {/*  Report Time AM View End  */}
                                    {/* Report Time PM View Start  */}
                                    <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                                        {data && <>
                                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                                {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                                {/* {orgData && orgData.logo ? (
                                                        <Avatar
                                                          sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                                          src={orgData.logo}
                                                        >
                                                        </Avatar>) : <></>} */}
                                            </div>
                                            <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                                {OrgName && OrgName}
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='col-8'>
                                                    <div className='d-flex flex-row gap-3'>
                                                        <div><strong>Date: </strong>{data.date && moment(data.date).format("ddd DD,YYYY")}</div>
                                                        {/* {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>} */}
                                                    </div>
                                                    <strong>Patient Name: </strong>{data.patientId}
                                                </div>
                                                <div className='d-flex justify-content-between col-4'>
                                                    <div>
                                                        <strong>Room No: </strong>
                                                        {data.assignedBed && data.assignedBed.split('-')[0]}
                                                    </div>
                                                    <div>
                                                        <strong className='pe-3'>AssignedBed:</strong>
                                                        {data.assignedBed && data.assignedBed.split('-')[1]}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: '1px solid #C9C9C9' }}>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                    <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                        <div>
                                                            CHECK PRECAUTIONS:
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Every 15 Minutes
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                1:1
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Eyesight
                                                            </label>
                                                            <div className="form-check" style={{ height: '15px' }}>
                                                                <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Agitated Behavior
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Arson
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Assault
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Fall
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Detox
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Elopement
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Homicide
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Sexual Acting Out
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Self Harm
                                                            </label>
                                                        </div>
                                                        <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                Other {`${'('}`}Describe{`${'):'}`}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                    <div>LOCATION CODES</div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                        {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                            <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                    {item.key}-{item.value}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                    <div>ACTIVITY CODES</div>
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                            <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                    {inx + 1}.{item.value}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='' style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                                <div style={{ width: '50%', fontSize: '14px' }}>
                                                    {data && data.configResponses?.map((t: any, ind: number) => {
                                                        return ind >= 48 && ind <= 71 && (<>
                                                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                                <div style={{ width: '50%', fontSize: '14px' }}>
                                                    {data && data.configResponses?.map((t: any, ind: number) => {
                                                        return ind >= 72 && ind <= 95 && (<>
                                                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}{t.timeSlot.split(' ')[1] === "AM" ? "+1" : ""}</div>
                                                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                            </div>

                                            <div style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                </div>
                                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                                    <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                                    <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </section>
                                    {/* Single Report Time PM View End  */}
                                </div>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    )
}

export default EyeSightPatientReportView