import axios from "axios";
import { getOrganizationDetailsSuccess,isLoading,setIsLoadingFalse,setErrorMessage, getTotalElementsSuccess } from "./reducer";
import { toast } from "react-toastify";
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from "../../helpers/common";
import { getOrgByID } from "../organization/thunk";

export const getAllOrganizationDetails = async (dispatch: any, pageNo: any, search: any) => {
    dispatch(isLoading())
    const { header1 } = authorize();
    try{
        const response=await axios.get(`${baseURL}/org/getAll?page=${pageNo}&pageSize=10&search=${search}`,{headers: header1})
        if(response.data.message.code===successCode){
          dispatch(setIsLoadingFalse())
            dispatch(getOrganizationDetailsSuccess(response.data.data.content));
            dispatch(getTotalElementsSuccess(response.data.data.totalElements));
        } else{
          dispatch(setIsLoadingFalse())
            console.warn(response.data.message.description)
        } 
    }
    catch(error){
        dispatch(setIsLoadingFalse())
        console.warn("API error:",error)
    }
}

export const updateOrganizationDetails = (id: string, data: any,currentPage:number) => async (dispatch: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response= await axios.put(`${baseURL}/org/update/${id}`, data,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse())
      getOrgByID(dispatch, id)
      toast.success(response.data.message.description) 
    } else {
      dispatch(setIsLoadingFalse())
      toast.error(response.data.message.description)
      dispatch(setErrorMessage(response.data.message.description));
    }
  } catch (error) {
    dispatch(setIsLoadingFalse())
    toast.error("Error: something went wrong.")
  }
};

  export const deleteOrganizationDetails = (id: string, currentPage:number) => async (dispatch: any) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
        const response = await axios.put(`${baseURL}/org/inactive/${id}`,{headers: header1});
        if (response.data.message.code === successCode) {
          getAllOrganizationDetails(dispatch, currentPage, '');
            dispatch(setIsLoadingFalse());
            toast.success(response.data.message.description)
        } else {
          dispatch(setIsLoadingFalse());
            dispatch(setErrorMessage(response.data.message.description));
            toast.error(response.data.message.description)
        }
    } catch (error) {
        dispatch(setIsLoadingFalse());
        toast.error("Error: something went wrong.")
    }
};

export const activeOrg = (id: string, currentPage:number) => async (dispatch: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
      const response = await axios.put(`${baseURL}/org/active/${id}`,{headers: header1});
      if (response.data.message.code === successCode) {
        getAllOrganizationDetails(dispatch, currentPage, '');
          dispatch(setIsLoadingFalse());
          toast.success(response.data.message.description)
      } else {
        dispatch(setIsLoadingFalse());
          dispatch(setErrorMessage(response.data.message.description));
          toast.error(response.data.message.description)
      }
  } catch (error) {
      dispatch(setIsLoadingFalse());
      toast.error("Error: something went wrong.")
  }
};