import React, { useState, useEffect, ReactNode } from "react";
import "./slider.css";

interface CustomCarouselProps {
  children: ReactNode[];
  staffData: any

}

const CustomCarousel: React.FC<CustomCarouselProps> = ({ children, staffData }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [slideDone, setSlideDone] = useState<boolean>(true);
  const [timeID, setTimeID] = useState<NodeJS.Timeout | null>(null);
  const [dataLength, setDataLength] = useState<number>(0)

  useEffect(() => {
    const filterData = staffData && staffData?.filter((item: any)=> item.sosDevice !== '' )
    setDataLength(filterData && filterData.length)
  }, [])

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 5000)
      );
    }
    // Clear timeout when component unmounts or dependencies change
    return () => {
      if (timeID) clearTimeout(timeID);
    };
  }, [slideDone]);

  const [num, setNum] = useState<number>(16)
  const slideNext = () => {
    if (activeIndex >= children.length - 1) {
      setActiveIndex(0)
      setNum(16)
    } else {
      if (dataLength >= num) {
        setActiveIndex(activeIndex + 1)
        setNum(num+num)
      } else {
        setActiveIndex(0)
        setNum(16)
      }
    }
  };

  const AutoPlayStop = () => {
    if (timeID) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
    >
      {children.map((item, index) => (
        <div
          className={`slider__item slider__item-active-${activeIndex + 1}`}
          key={index}
        >
          {item}
        </div>
      ))}

      <div className="container__slider__links">
        {children.map((_, index) => (
          <button 
            key={index}
            className={
              activeIndex === index
                ? "container__slider__links-small container__slider__links-small-active"
                : "container__slider__links-small"
            }
            onClick={(e) => {
              e.preventDefault();
              setActiveIndex(index);
            }}
          >
            {index + 1} {/* Display numbers instead of dots */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
