import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react'
import { IoPrintOutline } from 'react-icons/io5'




const Restraint = () => {
  const [restraintcheckbox, setRestraintCheckBox] = useState<Boolean>(false)
  const [seculsioncheckbox, setSeclusionCheckBox] = useState<Boolean>(false)

  const handleRestraint = () => {
    setRestraintCheckBox(!restraintcheckbox)
  }
  const handleSeclusion = () => {
    setSeclusionCheckBox(!seculsioncheckbox)
  }
  return (
    <>
    <div className='p-3'>
      <div className="d-flex justify-content-between">
        <div className="col-md-3">
          <h5 style={{ float: 'left' }}>Patient Round Sheet</h5>
        </div>
        <div className="col-md-2 d-flex justify-content-end ">
          <button style={{ backgroundColor: '#9c9a9a', border: 'none', padding:'4px 15px 4px 15px ', marginRight: '8px' }} 
            //   onClick={handlePrint}
          ><IoPrintOutline style={{color: 'black', fontSize: '20px'}}/>Print</button>
        </div>
      </div>
      <div className="row mx-0 bg-blue">
        <Autocomplete
              className="m-3"
              id="criteria"
              options={[]}
              // getOptionLabel={(option) => option}
              // value={addPatientName}
              // onChange={(e, v) => {
              //     setAddPatientName(v);
              //     let newPatientData = addNewPatient.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
              //     newInputData.patientName = newPatientData[0];
              //     setNewInputData({ ...newInputData });
              // }}
              sx={{ width: "95%" }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      label="Patient Name"
                      placeholder="Patient Name"
                      margin="none"
                      fullWidth
                  />
              )}
        />
        <div className='d-flex flex-row m-3  border-bottom'>
          <div className='col-4 mb-3'>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
              <DatePicker
                className=" "
                label="Date"
                sx={{ width: "90%" }}
                // value={value}
                // onChange={(newValue: any) => setValue(newValue)}
              />
            {/* </LocalizationProvider> */}
            <TextField
              label="Time authorized"
              placeholder=''
              className='my-3 '
              sx={{ width: "90%" }}
            />
            <Autocomplete
                  className=""
                  id=""
                  options={[]}
                  sx={{ width: "90%" }}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          label="Who authorized the original restraint?"
                          placeholder=""
                          margin="none"
                          fullWidth
                      />
                  )}
            />
            <TextField
              label="Total hours/min"
              placeholder=''
              className='my-3'
              sx={{ width: "90%" }}
            />
            <div style={{width: '90%'}} className='d-flex flex-row justify-content-between gap-4 '>
              <TextField
                label="Time In"
                placeholder=''
                className=''
                sx={{  }}
              />
              <TextField
                label="Time Out"
                placeholder=''
                className=''
                sx={{  }}
              />
            </div>
          </div>
          <div className='col-8 my-3'>
            <div className=' '><h6 style={{fontSize: '17px', color: 'black'}}>Least restrictive interventions used that were ineffective</h6></div>
            <div className='d-flex flex-row'>
              <div className='col-6' >
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Environmental modifications</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Re-Orientation, Redirection, De-Escalation</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Diversional activites, re-focus attention</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Quiet Room</h6>
                  </label>
                </div>
              </div>
              <div className='col-6' >
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Visual Supervision</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Limit Setting</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px'}}>Medication</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>1:1 Nursing Care</h6>
                  </label>
                </div>
                <div className="form-check my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" disabled  style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Other</h6>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-row mx-3 border-bottom'>
          <div className='col-4'>
            <div className="form-check" style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" onClick={handleRestraint} style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Restraint - 1:1 During Restraint Process</h6>
              </label>
            </div>
            <div className="form-check  my-3" style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" onClick={handleSeclusion} style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Seclusion - 1:1 for one hour at the seclusion</h6>
              </label>
            </div>
          </div>
          <div hidden={!restraintcheckbox} className='col-4'>
            <div className=''><h6>Reason for Restraint Use</h6></div>
            <div className="form-check mt-4 " style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Harm to Self</h6>
              </label>
            </div>
            <div className="form-check my-3 " style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Harm to Other</h6>
              </label>
            </div>
          </div>
          <div hidden={!seculsioncheckbox} className='col-4 '>
            <div className=''><h6>Reason for Seclusion</h6></div>
            <div className="form-check mt-4 " style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Harm to Self</h6>
              </label>
            </div>
            <div className="form-check my-3 " style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Harm to Other</h6>
              </label>
            </div>
          </div>
        </div>
        <div className='d-flex flex-row mx-3 border-bottom'>
          <div className='col-3 my-3 '>
            <div><h6 style={{fontSize: '16px'}}>TYPE OF ORDER</h6></div>
            <div className="form-check  my-3" style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Restraint</h6>
              </label>
            </div>
            <div className="form-check  my-3" style={{height: '11px'}}>
              <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
              <label className="" style={{  }} >
                <h6 style={{fontSize: '14px', color: 'black'}}>Seclusion</h6>
              </label>
            </div>
          </div>
          <div className='col-5 my-3'>
            <div><h6 style={{fontSize: '16px'}}>TYPE OF RESTRAINT</h6></div>
            <div style={{gap: '100px'}} className='d-flex flex-row my-3'>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='restraint' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                </label>
              </div>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='restraint' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                </label>
              </div>
            </div>
            <div className='d-flex flex-row'>
              <div className='col-4'>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Leather</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Hard Leather</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Soft</h6>
                  </label>
                </div>
              </div>
              <div className='col-4'>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Physical</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>4 Points</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>2 Points</h6>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4 my-3'>
            <div><h6 style={{fontSize: '16px'}}>CLOTHING REMOVED</h6></div>
            <div style={{gap: '75px'}} className='d-flex flex-row my-3'>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='seclusion' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                </label>
              </div>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='seclusion' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                </label>
              </div>
            </div>
            <div className='d-flex flex-row'>
              <div className='col-4'>
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Jacket</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Socks</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Pants</h6>
                  </label>
                </div>
              </div>
              <div className='col-4'>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Shoes</h6>
                  </label>
                </div>
                <div className="form-check  my-3" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Shirts</h6>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-row m-3 border-bottom'>
          <div className='col-4 '>
            <div><h6 style={{fontSize: '16px', color: 'black'}}>PLACEMENT</h6></div>
            <div className='d-flex flex-row' style={{gap: '44px'}}>
              <div className="form-check  my-3" style={{height: '11px'}}>
                <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Right Arm</h6>
                </label>
              </div>
              <div className="form-check  my-3" style={{height: '11px'}}>
                <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Left Arm</h6>
                </label>
              </div>
            </div>
            <div className='d-flex flex-row gap-5'>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Right Leg</h6>
                </label>
              </div>
              <div className="form-check" style={{height: '11px'}}>
                <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Left Leg</h6>
                </label>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <div className='d-flex flex-row gap-5'>
              <TextField
                label="Guardian/Family Notified"
                placeholder=''
                className=''
                sx={{ width: "90%" }}
              />
              <TextField
                label="Guardian/Family Notified"
                placeholder=''
                className=''
                sx={{ width: "90%" }}
              /> 
            </div>
            <div style={{width: '100%'}} className=''>
              <TextField
                label="Patient Educated on why Seclusion or Restraint Was used"
                placeholder=''
                className='my-3'
                sx={{ width: "100%" }}
              />
            </div>
            <div>
              <div><h6 style={{fontSize: '16px', color: 'black'}}>Searched for Contraband</h6></div>
              <div className='d-flex flex-row gap-5 my-3'>
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='contraband' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                  </label>
                </div>
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='contraband' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='m-3 border-bottom'>
          <div><h6 style={{fontSize: '16px', color: 'black'}}>One Hour RN/PHYSICIAN ASSESSMENT (FACE TO FACE)</h6></div>
          <div className='col-8 mt-2 '>
            <h6 style={{fontSize: '16px'}}>
              Patient Behavioral Response to Seclusion/Restraint - Must Complete for Physical Holds also 
            </h6>
            <h6 className='mt-3' style={{fontSize: '16px'}}>
              Are there any physical or psychological reasons not to utilize seclusion/restranit? 
            </h6>
            <div style={{width: '100%'}} className='d-flex flex-row my-3'>
              <div className="form-check col-6" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='contraband' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                </label>
              </div>
              <div className="form-check col-6" style={{height: '11px'}}>
                <input className="form-check-input" type="radio" name='contraband' style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                </label>
              </div>
            </div>
          </div>
          <div className='my-3 d-flex flex-row'>
            <div className='col-4'>
              <TextField
                label="RN/MD/DO Assessmentof:"
                placeholder=''
                className=''
                sx={{ width: "90%" }}
              />
            </div>
            <div className='col-4'>
              <TextField
                label="Patient's immediate situation"
                placeholder=''
                className=''
                sx={{ width: "90%" }}
              />
            </div>
            <div className='col-4'>
              <div className="form-check m-3" style={{height: '11px'}}>
                <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                <label className="" style={{  }} >
                  <h6 style={{fontSize: '14px', color: 'black'}}>Reaction to Intervention</h6>
                </label>
              </div>
            </div>
          </div>
          <div style={{width: '100%'}}>
            <div><h6 style={{fontSize: '16px', color: 'black'}}>Medical Condition Reviewed</h6></div>
            <div className='d-flex flex-row'>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>H&P</h6>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Labs</h6>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Progress Notes</h6>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Meds</h6>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>IPE</h6>
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check" style={{height: '11px'}}>
                  <input className="form-check-input" type="checkbox" style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Current VS</h6>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='my-3'>
            <div><h6 style={{fontSize: '16px', color: 'black'}}>Current VS</h6></div>
            <div className='d-flex flex-row justify-content-between'>
              <div className="col-2">
                <TextField
                  label="BP"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-2">
                <TextField
                  label="HR"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-2">
                <TextField
                  label="RR"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-2">
                <TextField
                  label="Sp02"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-2">
                <TextField
                  label="Temp"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className='my-4 d-flex flex-row'>
            <div className='col-4'>
              <div><h6 style={{fontSize: '16px', color: 'black'}}>Changes in medical condition</h6></div>
              <div className='d-flex flex-row gap-5'>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='medcondition' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                  </label>
                </div>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='medcondition' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                  </label>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <div style={{width: '100%'}}>
                <TextField
                  label="Behavioral Condition:"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className='my-4 d-flex flex-row'>
            <div className='col-4'>
              <div><h6 style={{fontSize: '16px', color: 'black'}}>Need to continue restraint</h6></div>
              <div className='d-flex flex-row gap-5'>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='continueRestraint' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>Yes</h6>
                  </label>
                </div>
                <div className="form-check " style={{height: '11px'}}>
                  <input className="form-check-input" type="radio" name='continueRestraint' style={{padding: '0.5px', border: '1px solid black'}}/>
                  <label className="" style={{  }} >
                    <h6 style={{fontSize: '14px', color: 'black'}}>No</h6>
                  </label>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <div style={{width: '100%'}}>
                <TextField
                  label="Behavioral Condition:"
                  placeholder=''
                  className=''
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div  className='my-3' >
          <div className='d-flex flex-row '>
            <div className='col-6'>
              <div className='d-flex flex-row '>
                <div className='col-6'>
                  <DatePicker
                    className=" "
                    label="Date"
                    sx={{ width: "90%" }}
                    // value={value}
                    // onChange={(newValue: any) => setValue(newValue)}
                  />
                </div>
                <div className='col-6'>
                  <TimePicker
                    className=""
                    label="Time"
                    sx={{ width: "90%" }}                
                  />
                </div>
              </div>
            </div>
            <div className='col-6'></div>
          </div>
        </div>
        <div className='my-3 d-flex flex-row '>
          <div className='col-6'>
              <TextField
                label="Name"
                placeholder=''
                className=''
                sx={{ width: "95%" }}
              />             
          </div>
          <div className='col-6'>
            <TextField
              label="Singnature"
              placeholder=''
              className=''
              sx={{ width: "103%" }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Restraint