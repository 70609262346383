import React, { useEffect, useState } from 'react'
import { secondarybt } from '../../common/primary'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Autocomplete, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRNIncharge, getAllStaff, getPatientDropdown } from '../../slices/thunk'
import moment from 'moment'
import { HttpLogin } from '../../utils/Http'
import { successCode } from '../../configuration/url'

const OneHourReport = () => {
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const dispatch = useDispatch();
    const org = useSelector((state: any) => state.Login.organization);
    const { rnInchargeList } = useSelector((state: any) => state.PSConfig)
    const { userData } = useSelector((state: any) => state.Login);
    const userId = userData.userDetail.id;
    const { staffData } = useSelector((state: any) => state.Staff);
    let [loginStaff, setLoginStaff] = useState<any>("");
    let [addNewStaff, setAddNewStaff] = useState<any>([]);

    const [inchargeList, setInchargeList] = useState<any>([])
    let [getStaffData, setStaffData] = useState<any>([]);
    let [newTimeSlot, setNewTimeSlot] = useState<any>([]);
    const addInputData = {
        shiftIncharge: "",
        criteria: "",
        patientName: "",
        slot: "",
        shift: [""],
        startDate: null,
        endDate: null
    }

    let [newInputData, setNewInputData] = useState(addInputData);
    let [addCalendarDate, setAddCalendarDate] = useState<any>([]);
    let [calendarDate,] = useState(new Date());
    let [addPatientName, setAddPatientName] = useState("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());

    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    function removeDuplicates(arr: any[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }
    useEffect(() => {
        HttpLogin.axios().get("/api/org/getById/" + org)
            .then((res) => {
                if (res.data.message.code === successCode) {
                    var newResult = res.data.data.shift.startTime !== "" ? res.data.data.shift.startTime : "08:00";
                    const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
                        let slotStart = new Date(fromTime).valueOf();
                        let slotEnd = new Date(fromTime).valueOf() + slotLength * 1000;
                        let endEpoch = new Date(toTime).valueOf();
                        let ob = [];
                        for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                            ob.push(formatDate(slotStart));
                            slotStart = slotEnd;
                        }
                        return ob;
                    }
                    const from = "2022-05-25 " + newResult;
                    const to = "2022-05-26 " + newResult;
                    const slotLength = 15 * 60;
                    var r = createTimeSlots(from, to, slotLength);
                    setNewTimeSlot(r);
                }
            })
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        var dayCalendar = [];
        dayCalendar.push(moment(new Date(from.setDate(from.getDate()))).format("YYYYMMDD"));
        setAddCalendarDate(dayCalendar);
    }, [org]);
    useEffect(() => {
        getPatientDropdown(dispatch, org)
        getAllRNIncharge(dispatch, 'Registered Nurse', org)
        getAllStaff(dispatch, org, -1, '');
    }, [org])
    useEffect(() => {
        if (rnInchargeList) {
            setInchargeList(rnInchargeList !== null && rnInchargeList !== undefined && rnInchargeList?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))
        } else {
            setInchargeList([])
        }
    }, [rnInchargeList])
    useEffect(() => {
        let newLoginstaff = staffData !== null && staffData !== undefined && staffData?.filter((t: any) => ((t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner") && (t.id === userId)))
        if (newLoginstaff.length > 0) {
            setLoginStaff(newLoginstaff[0].name[0].given + " " + newLoginstaff[0].name[0].family);
        }
        setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
        setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))

    }, [staffData, userId])
    const handleDateChange = (event: any) => {
        const d = new Date(event)
        let w = d.getFullYear().toString()
        if (w.length !== 4) return
        newInputData.startDate = event;
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        let dayCalendar = [];
        var to = newInputData.endDate !== null ? new Date(newInputData.endDate) : new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate());
        for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
            dayCalendar.push(moment(new Date(day)).format("YYYYMMDD"));
        }
        setAddCalendarDate(removeDuplicates(dayCalendar));
    }

    const handleNewDateChange = (event: any) => {
        const d = new Date(event)
        let w = d.getFullYear().toString()
        if (w.length !== 4) return
        newInputData.endDate = event;
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        var dayCalendar = [];
        var to = newInputData.endDate !== null ? new Date(newInputData.endDate) : new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate());
        for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
            dayCalendar.push(moment(new Date(day)).format("YYYYMMDD"));
        }
        setAddCalendarDate(removeDuplicates(dayCalendar));
    }

    return (
        <div className='px-2'>
            <div className='d-flex justify-content-end ' >
                <div className='d-flex gap-2'>
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', marginLeft: '0px' }}>View</button>
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', marginLeft: '0px' }}>Print</button>
                </div>
            </div>
            <div className='d-flex '>
                <div className="col-md-3 d-flex flex-column pe-1 pt-3" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker className="m-3 " label="Start Date"
                            value={newInputData.startDate}
                            onChange={handleDateChange}
                        />
                        <DatePicker className="m-3" label="End Date"
                            value={newInputData.endDate}
                            onChange={(e) => { handleNewDateChange(e) }}
                        />
                    </LocalizationProvider>

                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={patientDropdown}
                        getOptionLabel={(option: any) => option.name || ""}
                        value={addPatientName}
                        onChange={(e, v: any) => {
                            setAddPatientName(v);
                            const matchedPatient = patientDropdown?.find(
                                (patient: any) => patient.name === v.name
                            );
                            if (matchedPatient) {
                                newInputData.patientName = matchedPatient.id;
                                setNewInputData({ ...newInputData });
                            }
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Patient Name"
                                placeholder="Patient Name"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={inchargeList}
                        getOptionLabel={(option: any) => option}
                        // value={addNurseIncharge || ""}
                        // onChange={(e, v) => {
                        //     addNurseIncharge = v;
                        //     setAddNurseIncharge(v);
                        //     let newStaffData = rnInchargeList.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k: any) => { return k.id });
                        //     newInputData.shiftIncharge = newStaffData[0];
                        //     setNewInputData({ ...newInputData });
                        // }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Nurse Incharge"
                                placeholder="Nurse Incharge"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getStaffData}
                        // disabled={userType === 'Staff' && loginStaff.length > 0}
                        getOptionLabel={(option: any) => option}
                        // value={userType === 'Staff' ? (loginStaff.length > 0 && loginStaff) : addEnteredBy}
                        // onChange={(e, v) => {
                        //     addEnteredBy = v;
                        //     setAddEnteredBy(v);
                        //     let newStaffData = addNewStaff
                        //         .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === v)
                        //         .map((k) => k.id);
                        //     newInputData.criteria = newStaffData[0];
                        //     setNewInputData({ ...newInputData });
                        // }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Entered By"
                                placeholder="Entered By"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div className="col-md-9">

                </div>
            </div>
        </div>
    )
}

export default OneHourReport