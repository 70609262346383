import moment from 'moment';

export function formatDateToYYYYMMDD(dateObject: any) {
    if (!dateObject) return ''
    const year = dateObject.$y
    const month = (dateObject.$M + 1).toString().padStart(2, '0')
    const day = dateObject.$D.toString().padStart(2, '0')
    const formattedDate: string = `${year}${month}${day}`
    return formattedDate
}

export function formatPhoneNumber(value: any) {
    if (!value) return ""
    const phoneNumber = value?.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export function formatSSN(ssn: string): string {
    if (!ssn) return '';
    const cleanedSSN = ssn.replace(/[^\d]/g, '');
    const limitedSSN = cleanedSSN.slice(0, 9);
    const ssnLength = limitedSSN.length;

    if (ssnLength < 4) {
        return limitedSSN;
    } else if (ssnLength < 6) {
        return `${limitedSSN.slice(0, 3)}${limitedSSN.slice(3)}`;
    } else {
        return `${limitedSSN.slice(0, 3)}${limitedSSN.slice(3, 5)}${limitedSSN.slice(5)}`;
    }
}

export function authorize() {
    const jwt = localStorage.getItem('jwt');
    const header1 = { Authorization: 'Bearer ' + jwt }
    return { header1 };
}

export const formatDate1 = (dateString: any) => {
    if (!dateString) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${month}-${day}-${year}`;
};
export function formatEmail(email: string): string {
    if (!email) return '';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        throw new Error('Invalid email format');
    }
    return email.trim().toLowerCase();
};
export const formatDateTime = (dateTimeString: string): string => {
    if (!dateTimeString) return '';

    if (dateTimeString.includes('AM') || dateTimeString.includes('PM')) {
        return moment(dateTimeString, 'hh:mm A').format('MMM DD, YYYY hh:mm A');
    }

    return moment(dateTimeString, 'YYYYMMDDHHmmss').format('MMM DD, YYYY hh:mm A');
};

export function allowOnlyNumbers(input: string) {
    return input.replace(/[^0-9]/g, '');
}
export function formatMacAddress(input: string): string {
    if (!input) return '';
    const cleanedInput = input.replace(/[^a-fA-F0-9]/g, '').slice(0, 12);
    const formattedValue = cleanedInput
        .match(/.{1,2}/g)
        ?.join(':') || '';
    return formattedValue;
}
export function formatTimeTo12Hour(time: string) {
    return moment(time, 'HH:mm').format('hh:mm A');
};
export function formatTimeTo12Hour1(time: any) {
    if (!time) return "";
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour >= 12) {
        period = "PM";
        if (formattedHour > 12) formattedHour -= 12;
    } else if (formattedHour === 0) {
        formattedHour = 12;
    }

    return `${formattedHour}:${minute} ${period}`;
};

export function formatTo12Hour2(hour: any) {
    const period = hour >= 12 ? "PM" : "AM";
    let formattedHour = hour % 12 || 12;
    return formattedHour.toString().padStart(2, "0") + ":00 " + period;
}
export function formatTo12HourNoAmPm(hour: number): string {
    const hour12 = hour % 12 || 12;
    return `${hour12.toString().padStart(2, "0")}:00`;
};

export function formatTo12HourWithAmPm(hour: number): string {
    const hour12 = hour % 12 || 12;
    const amPm = hour < 12 ? 'AM' : 'PM';
    return `${hour12.toString().padStart(2, "0")}:00 ${amPm}`;
};


