import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import DateBox from "../../components/calendar/dateBox";
import { Button } from "reactstrap";
import { Box, Tabs, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AddShiftStaff from "./AddShiftStaff";
import moment from "moment";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { authorize } from "../../helpers/common";
import { useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        width: "85%",
        backgroundColor: "#f2f5f9",
        borderLeft: "1px solid #0303031f",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "14px 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Q15Shift = () => {
  const { header1 } = authorize();
  const [allTeamData, setAllTeamData] = useState<any>([]);
  const org = useSelector((state: any) => state.Login.organization);
  const { orgData, shiftStartTime } = useSelector((state: any) => state.Org);
  const [inCharge, setInCharge] = useState<any>('');
  const [Loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedDate1, setSelectedDate1] = useState<any>();
  const [date, setDate] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [noOfShift, setNoOfShift] = useState<any>(0);
  const [nextDate, setNextDate] = useState<any>('')
  const [nextDaydate, setNextDayDate] = useState<any>('')
  const [previousCheck, setPreviousCheck] = useState<boolean>(false)
  const [datechangecheck, setdatechangecheck] = useState<boolean>(false)
  const [checkdate, setCheckdate] = useState<boolean>(false)
  const handleChange = (newValue: number) => {
    setValue(newValue);
    setdatechangecheck(!datechangecheck)
  };
  useEffect(() => {
    if (renderTabContent() !== "General" && renderTabContent() !== "No Content Available" && renderTabContent() !== "Previous" && checkdate) {
      setCheckdate(false)
    }
  }, [previousCheck, value])
  useEffect(() => {
    if (!selectedDate) return
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
    const tabSlotTime = previousCheck ? moment(nextDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
    setSelectedDate(moment(tabSlotTime).format('YYYY-MM-DD'))
  }, [datechangecheck])
  const handleDateChange = (e: any) => {
    setSelectedDate(e.target.value);
  };
  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }

    return 24 / duration; // Return the number of shifts
  };
  const handlestartTime = () => {
    const crtime: any = localStorage.getItem("LTime");
    if (crtime) {
      const formattedDate = moment(crtime).format("YYYY-MM-DD");
      const currentTime = moment(crtime).format('HH:mm')
      const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
      const CurrTime: any = moment(`1970-01-01T${currentTime}`)
      if (shiftStartTime !== CurrTime) {
        if (CurrTime.isAfter(OrgStartTime)) {
          setSelectedDate(formattedDate);
          setSelectedDate1(formattedDate)
        } else {
          if (!formattedDate) return;
          const validDate = moment(formattedDate);
          if (validDate.isValid()) {
            const nextDay = moment(formattedDate).subtract(1, "days").format('YYYY-MM-DD');
            setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
            setSelectedDate1(formattedDate)
          } else {
            console.error("Invalid date:", formattedDate);
          }
        }
      } else {
        setSelectedDate(formattedDate);
        setSelectedDate1(formattedDate)
      }
    }
  };
  useEffect(() => {
    handlestartTime();

    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    setNoOfShift(numShift);
  }, []);
  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  };
  const renderShiftTab = () => {
    switch (noOfShift - 1) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  }
  const getAllTeam = async () => {
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
    try {
      setLoading(true);
      const ShiftName = renderTabContent()
      const tabShiftName = ShiftName === "Previous" ? renderShiftTab() : ShiftName
      const tabSlotTime = previousCheck ? moment(nextDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
      const response = await axios.get(
        `${baseURL}/shiftStaff/shiftStaffDropDown?date=${tabSlotTime}&organization=${org}&shiftName=${tabShiftName}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        if (response.data.data?.staff?.length > 0) {
          setLoading(false);
          setAllTeamData(response.data.data?.staff);
          setInCharge(response.data.data?.rnIncharge);
          setPreviousCheck(false)
        } else {
          setAllTeamData([]);
          setLoading(false);
          setInCharge('');
          setPreviousCheck(false)
        }
        setLoading(false);
        setPreviousCheck(false)
      } else {
        setLoading(false);
        setAllTeamData([]);
        setInCharge('');
        setPreviousCheck(false)
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setPreviousCheck(false)
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!selectedDate) return
    getAllTeam();
  }, [selectedDate, value]);

  const [shifts, setShifts] = useState<any>([]);
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    for (let i = 0; i < totalShifts; i++) {
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      );
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);
      const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });
      startTime = endTime;
    }
    setShifts(shifts);
  };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
  }, [orgData, noOfShift])
  const [dateRange, setDateRange] = useState<any>()
  useEffect(() => {
    if (!shifts) return
    if (previousCheck) {
      setDateRange(shifts[noOfShift - 1])
    } else if (renderTabContent() === "General") {
      setDateRange(shifts[0])
    } else {
      setDateRange(shifts[value])
    }
  }, [shifts, value, previousCheck])

  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().subtract(1, 'days');
      setNextDate(moment(nextDay)?.format('YYYY-MM-DD'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      setNextDayDate(moment(nextDay)?.format('YYYY-MM-DD'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!previousCheck) return
    if (!nextDate) return;
    const validDate = moment(nextDate);
    if (validDate.isValid()) {
      setSelectedDate(moment(nextDate).format('YYYY-MM-DD'))
      // getAllTeam()
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [value, previousCheck])
  return (
    <div>
      {Loading && <Loader />}

      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            width: "90%",
            position: "relative",
            left: "66px",
          }}
        >
          <DateBox
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleDateChange={handleDateChange}
            countCheck={null}
            dateRange={dateRange}
          />
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor: "#eaf2fa",
            borderRight: "1px groove #ccd2d8",
          }}
        >
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <button
              className={`nav-link ${value === 7 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls=""
              aria-selected={value === 7}
              onClick={() => { handleChange(7); setPreviousCheck(true); setCheckdate(true) }}
              style={{
                backgroundColor: value === 7 ? "#cddcfa" : "",
                color: value === 7 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              <IoMdArrowBack style={{ fontSize: '18px', paddingBottom: '2px' }} />
              Previous
            </button>
            {Array.from({ length: noOfShift }).map((_, index) => (
              <button
                className={`nav-link ${value === index ? "active" : ""}`}
                id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                type="button"
                role="tab"
                aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                aria-selected={value === index}
                onClick={() => handleChange(index)}
                style={{
                  backgroundColor: value === index ? "#cddcfa" : "",
                  color: value === index ? "" : "black",
                  width: "91%",
                  height: "40px",
                  borderRadius: "4px",
                }}
              >
                {`Shift-${String.fromCharCode(65 + index)}`}
              </button>
            ))}

            <button
              className={`nav-link ${value === 6 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls="Shift-A"
              aria-selected={value === 6}
              onClick={() => handleChange(6)}
              style={{
                backgroundColor: value === 6 ? "#cddcfa" : "",
                color: value === 6 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              General
            </button>
          </div>

          {Array.from({ length: noOfShift }).map((_, index) => (
            <TabPanel value={value} index={index}>
              <AddShiftStaff
                getAllTeam={getAllTeam}
                selectedDate={selectedDate}
                selectedTab={value}
                allTeamData={allTeamData}
                noOfShift={noOfShift}
                inCharge={inCharge}
              />
            </TabPanel>
          ))}
          <TabPanel value={value} index={6}>
            <AddShiftStaff
              getAllTeam={getAllTeam}
              selectedDate={selectedDate}
              selectedTab={value}
              allTeamData={allTeamData}
              noOfShift={noOfShift}
              inCharge={inCharge}

            />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <AddShiftStaff
              getAllTeam={getAllTeam}
              selectedDate={selectedDate}
              selectedTab={value}
              allTeamData={allTeamData}
              noOfShift={noOfShift}
              inCharge={inCharge}

            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default Q15Shift;
