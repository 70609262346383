import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OneToOneReportView from '../OneToOneReport/OneToOneReport';
import ControlledAccordions from '../q15Report';
import { grey, primarytext, secondarybt } from '../../common/primary';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivityLegend, fetchLocationLegend, getPatientDropdown } from '../../slices/thunk';
import moment from 'moment';
import { Button, Table } from 'reactstrap';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import MultipleReportView from '../OneToOneReport/MultipleReportView';
import printImage from '../../assets/images/printer.svg'
import ReportView from './ReportView';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Loader from '../../components/loader/Loader';
import OneHourReport from '../OnehourReport';

const AllReport = () => {
    const addInputData = {
        date: '',
        patientName: ''
    }
    const { orgData } = useSelector((state: any) => state.Org);
    const [values, setValues] = useState<any>('')
    const [value, setValue] = React.useState('1');
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    };
    const org = useSelector((state: any) => state.Login.organization)

    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const dispatch = useDispatch<any>()
    const option = ['Shift-A', 'Shift-B', 'Shift-C']
    let [addPatientName, setAddPatientName] = useState("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [multiReportData, setMultiReportData] = useState<any>()
    const [reportData, setReportData] = useState<any>([])
    const [newInputData, setNewInputData] = useState(addInputData)
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    let [outputSave, setOutputSave] = useState(false);
    const [checkSubmit, setCheckSubmit] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkSelect, setCheckSelect] = useState<number>(1)
    const [viewContentData, setViewContent] = useState<boolean>(true);
    const [accordionView, setAccordionView] = useState<boolean>(false)
    let [dates, setDates] = useState<any>();
    let [selectdate, setSelectDate] = useState<any>(new Array<any>());
    const [singleDate, setSingleDate] = useState<any>();
    const [selectShift, setSelectShift] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [selectShiftView, setSelectShiftView] = useState<boolean>(false);
    const [checkBox, setCheckBox] = useState<boolean>(false);
    const [checkPrecaution, setCheckPrecaution] = useState<any>('')
    const { locationLegend, activityLegend } = useSelector((state: any) => state.Dropdown);

    const [legendcontrol, setLegendControl] = useState<boolean>(false)

    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegendControl(e.target.checked)
    }
    // EyeSight Tab click to initial value
    const handleEyesight = () => {
        setCheckPrecaution("Eyesight")
        setSelectedRows([])
        setReportData([])
        setReportData([])
        setNewInputData(addInputData)
        setCheckSelect(1)
        setAddPatientName('')
        setValues('')
        setSelectShift('')
        setDisabled(false)
        setSelectShiftView(false)
        setViewContent(true)
    }

    // location & activity legend
    useEffect(() => {
        fetchLocationLegend(dispatch, org)
        fetchActivityLegend(dispatch, org)
    }, [org])

    useEffect(() => {
        getPatientDropdown(dispatch, org)
    }, [org])

    useEffect(() => {
        setDates(selectdate ? selectdate?.map((item: any, inx: number) => { return item.date }) : []);
        if (selectdate === null || selectdate === undefined) {
            setValues('')
        }
    }, [selectdate, addPatientName, newInputData]);

    const handleCheckSelect = (n: any) => {
        setCheckSelect(n)
        if (n === 1) {
            setAccordionView(false)
            setSelectedRows([])
            setReportData([])
        } else {
            setOutputSave(false)
            setSelectedRows([])
            setReportData([])
        }
    }

    // Proxmity select checkbox function
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(e.target.checked);
    };

    // Patient Report Date UseEffect
    useEffect(() => {
        const getDropDownDate = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`${baseURL}/register1to1/getDateList?patientId=${newInputData.patientName}`)
                if (response) {
                    setSelectDate(response.data.data)
                    setLoading(false)
                } else {
                    console.error("Error");
                    setSelectDate([]);
                    setLoading(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSelectDate([]);
                setLoading(false)
            }
        }
        getDropDownDate();
    }, [newInputData, addPatientName]);
    // Tomorrow Date 
    const [nextDate, setNextDate] = useState<any>('')
    useEffect(() => {
        if (!singleDate) return;

        const validDate = moment(singleDate[0]);
        // console.log(validDate, 'validDate')
        if (validDate.isValid()) {
            const nextDay = validDate.clone().add(1, 'days');
            console.log(moment(nextDay)?.format('MMM DD, YYYY'), 'validDate')
            setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
        } else {
            console.error("Invalid date:", singleDate);
        }
    }, [singleDate]);

    useEffect(() => {
        if (!values) return
        setNewInputData({ ...newInputData, date: moment(value).format("YYYYMMDD") });
    }, [values])

    // select Mulit Date
    const handleRowSelected = (item: string) => {
        const index = selectedRows.findIndex(selectedItem => selectedItem === moment(item).format("YYYYMMDD"));
        if (index > -1) {
            setSelectedRows(selectedRows.filter((_, i) => i !== index));
        } else {
            setSelectedRows([...selectedRows, moment(item).format("YYYYMMDD")]);
        }
    };

    const handlesumbit = async () => {

        if (checkSelect === 1) {
            let newErrors = {
                patientName: !newInputData.patientName,
                date: !newInputData.date
            };
            setNameError(newErrors);
            const hasErrors = Object.values(newErrors)?.some(error => error);
            if (hasErrors) {
                setCheckSubmit(true)
                toast.error("Please Fill Required Field")
                return;
            }
        } else {
            let newErrors = {
                patientName: !newInputData.patientName,
                selectedRows: !selectedRows?.length
            };
            setNameError(newErrors);
            const hasErrors = Object.values(newErrors)?.some(error => error);
            if (hasErrors) {
                setCheckSubmit(true)
                toast.error("Please Fill Required Field")
                return;
            }
        }
        try {
            setLoading(true)
            setMultiReportData([])
            setReportData([])
            let bodyDate: any[];
            if (checkSelect === 1) {
                bodyDate = singleDate
            } else {
                bodyDate = selectedRows
            }
            const response = await axios.post(`${baseURL}/registereyesight/geteyesightReportForMultipleDate?organization=${org}&patientId=${newInputData.patientName}&shiftName=${selectShift}`, { dates: bodyDate })
            if (response?.data.message && response?.data.message.code === successCode) {
                if (checkSelect === 1) {
                    setReportData(response.data.data)
                    setSelectShiftView(selectShift ? true : false)
                    setCheckBox(disabled)
                    setViewContent(false)
                } else {
                    setAccordionView(true)
                    setMultiReportData(response.data.data)
                    setReportData(response.data.data)
                    setSelectShiftView(selectShift ? true : false)
                    setCheckBox(disabled)
                    // setViewContent(!viewContentData)
                    if (checkSubmit === false) {
                        setViewContent(!viewContentData)
                    }
                }
            } else {
                console.error("Error:", response.data.message);
            }
            bodyDate = []
            setLoading(false)
        } catch (error: any) {
            console.error("API Request Error", error)
            setLoading(false)
        }
    }
    //print page
    const handlePrint = () => {
        let printContents = document.getElementById('1to1AllReport')!.innerHTML;
        let printWindow = window.open('', '_blank');
        printWindow!.document.body.innerHTML = printContents;
        printWindow!.print();
        printWindow!.close();
    }
    // Pdf genrate to download
    const handleDownload = async () => {
        if (reportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < reportData?.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(reportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add the first image to the second page (swap the first and second content)
                    pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    pdf.addPage(); // Create a new page

                    // Add the second image to the first page (swapped content)
                    pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

                    // Save the PDF with the swapped content
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    };
    return (
        <div className=''>
            <div className='d-flex justify-content-center'>
                <h5 >REPORTS</h5>
            </div>
            <Box>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {
                                orgData?.q15Access === 'Yes' &&
                                <Tab label="Q15-Report" value="1" onClick={() => { setCheckPrecaution("Q15") }} />
                            }
                            {
                                orgData?.q15OneToOne &&
                                <Tab label="One-To-One" value="2" onClick={() => { setCheckPrecaution("1:1") }} />
                            }
                            {
                                orgData?.eyeSight &&
                                <Tab label="Eyesight" value="3" onClick={() => { handleEyesight() }} />
                            }
                            {
                                orgData?.q15OneHour && 
                                <Tab label="One-Hour" value="4" onClick={() => { setCheckPrecaution("One-hour")}}/>
                            }
                        </TabList>
                    </Box>
                    {
                        orgData?.q15Access === 'Yes' &&
                        <TabPanel value="1" style={{ padding: '5px' }}>
                            <div>
                                <ControlledAccordions />
                            </div>
                        </TabPanel>
                    }
                    {
                        orgData?.q15OneToOne &&
                        <TabPanel value="2" style={{ padding: '5px' }}>
                            <div>
                                <OneToOneReportView checkPrecaution={checkPrecaution} />
                            </div>
                        </TabPanel>
                    }
                    {
                        orgData?.eyeSight &&
                        <TabPanel value="3" style={{ padding: '5px' }}>
                            <div className='container-fluid overflow-y-auto pb-5'>
                                {loading && <Loader />}
                                <div hidden={checkSelect === 2 && !viewContentData ? true : false}>
                                    <div className='pt-2'>
                                        <div className="d-flex justify-content-between">
                                            <div className='col-md-3' ></div>

                                            <div className="col-md-2 d-flex justify-content-end ">
                                                <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => {
                                                    if (checkSelect === 1) {
                                                        if (reportData?.length === 0) {
                                                            handlesumbit()
                                                            handleDownload()
                                                        }
                                                        handleDownload()
                                                    } else if (checkSelect === 2) {
                                                        if (!reportData.length) {
                                                            handlesumbit()
                                                            setCheckSubmit(false)
                                                        } else {
                                                            setViewContent(!viewContentData)
                                                        }
                                                    }
                                                }}
                                                // disabled={!viewContentData}
                                                >
                                                    {checkSelect === 1 ? 'Download' : 'View'}
                                                </button>
                                                {
                                                    checkSelect === 1 &&
                                                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><img src={printImage} />Print</button>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className=" d-flex flex-row mt-2" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px", height: '100px', width: '100%' }}>
                                                <div className='col-2  d-flex justify-content-center align-items-center '>
                                                    <div className='' >
                                                        <div className=''>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={checkSelect === 1 ? true : false} onChange={() => { handleCheckSelect(1) }} />}
                                                                label="Single Report"
                                                            />
                                                        </div>
                                                        <div className=''>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={checkSelect === 2 ? true : false}
                                                                        onChange={() => {
                                                                            if (!viewContentData) {
                                                                                setViewContent(true)
                                                                            }
                                                                            handleCheckSelect(2)
                                                                        }
                                                                        }
                                                                    />
                                                                }
                                                                label="Multi Report"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-3  d-flex justify-content-center align-items-center'>
                                                    <Autocomplete
                                                        options={patientDropdown}
                                                        getOptionLabel={(option: any) => option.name || ""}
                                                        value={addPatientName}
                                                        onChange={(e, v: any) => {
                                                            setAddPatientName(v);
                                                            if (v) {
                                                                setSelectedRows([]);
                                                                setMultiReportData([]);
                                                                setReportData([]);
                                                                const matchedPatient = addNewPatient?.find(
                                                                    (m: any) =>
                                                                        `${m.basicDetails?.[0]?.name?.[0]?.given || ""} ${m.basicDetails?.[0]?.name?.[0]?.family || ""}` === v
                                                                );
                                                                if (matchedPatient) {
                                                                    newInputData.patientName = matchedPatient.id;
                                                                    setNewInputData({ ...newInputData, date: "" });
                                                                }
                                                                setNameError({ ...nameError, patientName: false });
                                                                setOutputSave(false);
                                                                setCheckSubmit(true);
                                                                if (checkSelect === 1) {
                                                                    setViewContent(true);
                                                                }
                                                            }
                                                        }}
                                                        sx={{ width: "90%" }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Patient Name"
                                                                margin="none"
                                                                fullWidth
                                                                error={!!nameError.patientName}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-2  d-flex justify-content-center align-items-center  ' >
                                                    <Autocomplete
                                                        options={dates}
                                                        getOptionLabel={(option) => option}
                                                        value={outputSave === true ? values : ''}
                                                        onChange={(e, v: any) => {
                                                            setValues(v);
                                                            setSingleDate([moment(v).format("YYYYMMDD")])
                                                            // handleRowSelected(v)
                                                            // setSelectedRows([...selectedRows, moment(v).format("YYYYMMDD")])
                                                            setNameError({ ...nameError, date: false });
                                                            setOutputSave(true);
                                                            if (checkSelect === 1) {
                                                                setViewContent(true)
                                                            }
                                                        }}
                                                        freeSolo
                                                        sx={{ width: "82%" }}
                                                        disabled={checkSelect === 2 ? true : false}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Date"
                                                                error={!!nameError.date}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className='col-2  d-flex justify-content-center align-items-center  '>
                                                    <Autocomplete
                                                        options={option}
                                                        getOptionLabel={(option) => option}
                                                        value={selectShift}
                                                        onChange={(e, v) => {
                                                            if (reportData.length > 0) {
                                                                setSelectShift(v)
                                                                // setViewContent(!viewContentData)
                                                                // handlesumbit()
                                                                // setCheckSubmit(true)
                                                            } else {
                                                                setSelectShift(v)
                                                            }
                                                        }}
                                                        sx={{ width: "82%" }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Shift"
                                                                placeholder="Shift"
                                                                margin="none"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-2 d-flex flex-column py-1  ' >
                                                    <FormControlLabel
                                                        control={<Checkbox checked={disabled} onChange={handleCheck} />}
                                                        label="Proximity"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                                                        label={legendcontrol ? 'Legends Name' : 'Legends Key'}
                                                    />
                                                </div>
                                                <div className='col-1  d-flex justify-content-center align-items-center     '>
                                                    <Button label='submit' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => {
                                                        handlesumbit()
                                                        setCheckSubmit(true)
                                                    }}>Submit</Button>
                                                </div>
                                            </div>
                                            {
                                                checkSelect === 2 &&
                                                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }} className=''>
                                                    {(addPatientName && dates && dates.length > 0) && (
                                                        // {(addPatientName && dates) && (
                                                        <Table hover style={{ width: '100%', textAlign: 'center' }} >
                                                            <thead >
                                                                <tr className='' style={{ top: 0, position: 'sticky' }}>
                                                                    <th style={{ color: primarytext, backgroundColor: grey }} >Date</th>
                                                                    <th style={{ color: primarytext, backgroundColor: grey }} >Patient Name</th>
                                                                    <th style={{ color: primarytext, backgroundColor: grey }} >Shift</th>
                                                                    <th style={{ color: primarytext, backgroundColor: grey }} >Proximity</th>
                                                                    <th style={{ color: primarytext, backgroundColor: grey }} >Select</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {dates && dates.map((item: any, inx: number) => {
                                                                    return (
                                                                        <tr key={inx} className='' style={{}}>
                                                                            <td>{item && moment(item).format("MM-DD-YYYY")}</td>
                                                                            <td>{addPatientName}</td>
                                                                            <td>{selectShift ? selectShift : 'Full Shift'}</td>
                                                                            <td>{disabled ? disabled && "Proximity" : '-'}</td>
                                                                            <td>
                                                                                {/* <Checkbox onChange={() => { handleRowSelected(item) }} /> */}
                                                                                <input type='checkbox' checked={selectedRows.includes(moment(item).format("YYYYMMDD"))} onChange={() => {
                                                                                    handleRowSelected(item)
                                                                                }} />
                                                                            </td>
                                                                        </tr>)
                                                                })}
                                                            </tbody>
                                                        </Table >)}
                                                </div>
                                            }
                                            {/* Multi Report Summary&Details View Start */}
                                            <div className='mt-2'>
                                                {
                                                    checkSelect === 2 &&
                                                    <MultipleReportView
                                                        multiReportData={multiReportData}
                                                        View={accordionView}
                                                        selectShift={selectShift}
                                                        selectShiftView={selectShiftView}
                                                        checkBox={checkBox}
                                                        locationLegend={locationLegend}
                                                        activityLegend={activityLegend}
                                                        checkPrecaution={checkPrecaution}
                                                        legendcontrol={legendcontrol}
                                                    />
                                                }
                                            </div>
                                            {/* Multi Report Summary&Details View End */}
                                        </div>

                                    </div>
                                </div>
                                <div hidden={viewContentData} id='1to1AllReport' style={{ paddingLeft: '100px', paddingRight: '100px' }} >
                                    {
                                        checkSelect === 2 && !viewContentData &&
                                        <>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <div>
                                                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => {
                                                        handleDownload()
                                                    }}>Download</button>
                                                </div>
                                                <div>
                                                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px', }} onClick={() => {
                                                        handlePrint()
                                                    }}><img src={printImage} />Print</button>
                                                </div>
                                                <div>
                                                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => { setViewContent(!viewContentData) }}>Close</button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        reportData !== null && reportData !== undefined && <ReportView reportData={reportData} selectShiftView={selectShiftView} checkBox={checkBox} selectShift={selectShift} nextDate={nextDate} dateID={undefined} checkPrecaution={checkPrecaution} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={false} />
                                    }
                                </div>
                            </div>
                        </TabPanel>
                    }
                    {
                        orgData?.q15OneHour && 
                        <TabPanel value='4' style={{ padding: '5px' }}>
                            <OneHourReport/>
                        </TabPanel>
                    }
                </TabContext>
            </Box>
        </div>
    )
}

export default AllReport