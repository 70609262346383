import React, { useEffect, useState } from 'react'
import moment from 'moment';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import trilliumImage from './../../assets/images/trillium.png';
import { useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface MultipleReportViewProps {
  multiReportData: any
  View: any
  selectShift: any
  selectShiftView: any
  checkBox: any
  locationLegend: any
  activityLegend: any
  checkPrecaution: any
  legendcontrol: boolean
}

const MultipleReportView: React.FC<MultipleReportViewProps> = ({ multiReportData, View, selectShift, selectShiftView, checkBox, activityLegend, locationLegend, checkPrecaution, legendcontrol }) => {

  const [expanded, setExpanded] = useState<string | false>('panel1');
  const { orgData } = useSelector((state: any) => state.Org)
  const [OrgName, setOrgName] = useState<any>('')


  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    if (!orgData) return
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])
  return (
    <div>
      {/* Multi Report Summary&Details View Start */}
      <div className='container-fluid overflow-auto'>
        {View && multiReportData !== null && multiReportData !== undefined &&
          multiReportData.map((data: any, inx: number) => {
            return (
              <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDropDownIcon />}
                >
                  <Typography>
                    <span style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                      {moment(data.date).format("MMM DD, YYYY")}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <>
                      <div className='container-fluid overflow-auto' id='1to1MultiReport' >
                        {/* Report Time Am View Start */}
                        <section className='mt-3'>
                          {data && <>
                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                              {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null} </div>
                            <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                              {OrgName && OrgName}
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div className='col-8'>
                                <div className='d-flex flex-row gap-3'>
                                  <div><strong>Date: </strong>{data.date && moment(data.date).format("DD-MM-YYYY")} </div>
                                  {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                                </div>
                                <strong>Patient Name: </strong>{data.patientId}
                              </div>
                              <div className='d-flex justify-content-between col-4'>
                                <div>
                                  <strong>Room No: </strong>
                                  {data.assignedBed && data.assignedBed.split('-')[0]}
                                </div>
                                <div>
                                  <strong className='pe-3'>AssignedBed:</strong>
                                  {data.assignedBed && data.assignedBed.split('-')[1]}
                                </div>
                              </div>
                            </div>

                            { /* Previous Code Start */}
                            {/* <div className='mt-2'>
                             <table style={{ width: '100%', fontSize: '14px' }}>
                               <thead >
                                 <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign:'center', fontWeight: '600' }}>
                                   <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                                   <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                                   <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                                   <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                                   <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                                 </tr>
                               </thead>
                               <tbody style={{ border: '1px solid #C9C9C9' }}>
                                 <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px'}}>{data && data.shiftAIncharge}</th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftATime}</th>
                                   <th style={{ width: '15%', paddingLeft: '20px' }}>
                                   <div className="form-check " style={{height: '15px'}}>
                                     <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'low'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                                     <label className="" style={{fontSize: '16px', fontWeight: 'normal', color: 'black'}} >
                                       Low
                                     </label>
                                   </div>                     
                                   </th>
                                 </tr>
                                 <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px'}}>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftBIncharge}</th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftBTime}</th>
                                   <th style={{ width: '15%', paddingLeft: '20px' }}>
                                   <div className="form-check " style={{height: '15px'}}>
                                     <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'moderate'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                                     <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                                       Moderate
                                     </label>
                                   </div>                      
                                   </th>
                                 </tr>
                                 <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px'  }}>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftCIncharge}</th>
                                   <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                   <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftCTime}</th>
                                   <th style={{ width: '15%', paddingLeft: '20px' }}>
                                   <div className="form-check " style={{height: '15px'}}>
                                     <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'high'} style={{padding: '7px', border: '1.2px solid black'}}/>
                                     <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                                       High
                                     </label>
                                   </div>
                                   </th>
                                 </tr>
                               </tbody>
                             </table>
                           </div> */}
                            { /* Previous Code End */}
                            <div style={{ border: '1px solid #C9C9C9' }}>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                  <div>
                                    CHECK PRECAUTIONS:
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      1:1
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === '1:1'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Eyesight
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === 'Eyesight'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Every 15 Minutes
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === 'Q15'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Agitated Behavior
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Arson
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Assault
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Fall
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Detox
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Elopement
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Homicide
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Sexual Acting Out
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Self Harm
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Other {`${'('}`}Describe{`${'):'}`}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>LOCATION CODES</div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                      <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {item.key}-{item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>ACTIVITY CODES</div>
                              </div>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                  {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                    <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {inx + 1}.{item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {!selectShiftView ? <>
                              <div className='' style={{ fontSize: '14px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                  <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                  <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                </div>
                              </div>
                              <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                <div style={{ width: '50%', fontSize: '14px' }}>
                                  {data && data.configResponses?.map((t: any, ind: number) => {
                                    return ind >= 0 && ind <= 23 && (<>
                                      <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                      </div>
                                    </>);
                                  })}
                                </div>
                                <div style={{ width: '50%', fontSize: '14px' }}>
                                  {data && data.configResponses?.map((t: any, ind: number) => {
                                    return ind >= 24 && ind <= 47 && (<>
                                      <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                      </div>
                                    </>);
                                  })}
                                </div>
                              </div>
                            </>
                              :
                              <>
                                {/*  Report Shift based View Start */}
                                <div>
                                  <div className='' style={{ fontSize: '14px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                      <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                      <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                    </div>
                                  </div>
                                  <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                    <div style={{ width: '50%', fontSize: '14px' }}>
                                      {data && data.configResponses?.map((t: any, ind: number) => {
                                        return ind >= 0 && ind <= 15 && (<>
                                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                          </div>
                                        </>);
                                      })}
                                    </div>
                                    <div style={{ width: '50%', fontSize: '14px' }}>
                                      {data && data.configResponses?.map((t: any, ind: number) => {
                                        return ind >= 16 && ind <= 31 && (<>
                                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                          </div>
                                        </>);
                                      })}
                                    </div>
                                  </div>
                                </div>
                                {/* Report Shift based View End */}
                              </>}
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                              </div>
                              {[1, 2, 3, 4].map((t: any, indx: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                </div>
                              ))}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                              </div>
                            </div>
                            {/* <div style={{width: '100%',backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px'}}>
                             <div style={{width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px'}}>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600'}}>
                                 <div style={{width: '25%'}}>Location</div>
                                 <div style={{width: '25%'}}>Code</div>
                                 <div style={{width: '25%'}}>Location</div>
                                 <div style={{width: '25%'}}>Code</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>PT Room</div>
                                 <div style={{width: '25%'}}>R</div>
                                 <div style={{width: '25%'}}>Conf Rm</div>
                                 <div style={{width: '25%'}}>C</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>Day Room</div>
                                 <div style={{width: '25%'}}>DR</div>
                                 <div style={{width: '25%'}}>Mid Line</div>
                                 <div style={{width: '25%'}}>L</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>Dining RM</div>
                                 <div style={{width: '25%'}}>D</div>
                                 <div style={{width: '25%'}}>Quiet RM</div>
                                 <div style={{width: '25%'}}>QR</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>Nurses ST.</div>
                                 <div style={{width: '25%'}}>N</div>
                                 <div style={{width: '25%'}}>Shower</div>
                                 <div style={{width: '25%'}}>S</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>Telephone</div>
                                 <div style={{width: '25%'}}>T</div>
                                 <div style={{width: '25%'}}>Seclusion</div>
                                 <div style={{width: '25%'}}>SC</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '25%'}}>BathRoom</div>
                                 <div style={{width: '25%'}}>B</div>
                                 <div style={{width: '25%'}}>Hall</div>
                                 <div style={{width: '25%'}}>H</div>
                               </div>
                             </div>
                             <div style={{width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px'}}>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black'}}>
                                 <div style={{width: '100%', fontWeight: '600'}}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>1. Sleeping (3 respirations observed)</div>
                                 <div style={{width: '44%'}}>7. Oppositional</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>2. Calm, interacting appropriately</div>
                                 <div style={{width: '44%'}}>8. Bizarre behavior</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>3. Withdrawn/Quiet</div>
                                 <div style={{width: '44%'}}>9. Shouting/loud/agitated</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>4. Crying, tearful</div>
                                 <div style={{width: '44%'}}>10. Out of control, aggressive</div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>5. Anxious, restless</div>
                                 <div style={{width: '44%'}}></div>
                               </div>
                               <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                 <div style={{width: '56%'}}>6. Elated, hyperactive, manic</div>
                                 <div style={{width: '44%'}}></div>
                               </div>
                             </div>
                           </div> */}
                            {/* <article style={{ width: '100%' }} className=''>
                             <div style={{ width: '100%', fontSize: '13px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                               </div>
                             </div>
                             <div style={{ width: '100%', fontSize: '13px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                               </div>
                             </div>
                             <div style={{ width: '100%', fontSize: '13px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                               </div>
                             </div>
                             <div style={{ width: '100%', fontSize: '13px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                               </div>
                             </div>
                             <div style={{ width: '100%', fontSize: '13px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                                 <div style={{ width: '100%', border: '1px solid #C9C9C9',paddingBottom: '10px' }}>
                                   <div style={{padding:'1px 7px', fontWeight: '600'}}>PRECAUTIONS MEANING: </div>
                                   <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                                     <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                           SAO: SEXUALLY ACTING OUT
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                           ASP: ACTIVE SUICIDE PREC.
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                           FP: FALL PREC.
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                           GSP: GEN SAFETY PREC.
                                         </label>
                                       </div>
                                     </div>
                                     <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                          DETOX-ETOH
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                          DETOX-DRUG
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                          AP: ASSAULT PREC.
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                          EP: ELOPEMENT PREC
                                         </label>
                                       </div>
                                     </div>
                                     <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                         1:1: ONE TO ONE
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                         OBSERVATION
                                         </label>
                                       </div>
                                       <div className="form-check " style={{height: '11px'}}>
                                         <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{padding: '0.5px', border: '1px solid black'}}/>
                                         <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                         SZ: SEIZURE PREC.
                                         </label>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                 <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                               </div>
                             </div>
                           </article> */}
                          </>}
                        </section>
                        {/*  Report Time AM View End  */}
                        {/* Report Time PM View Start  */}
                        {!selectShiftView && <section className='mt-3'>
                          {data && <>
                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                              {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}

                              {/* {orgData && orgData.logo ? (
                                <Avatar
                                  sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                  src={orgData.logo}
                                >
                                </Avatar>) : <></>} */}
                            </div>
                            <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                              {OrgName && OrgName}
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div className='col-8'>
                                <div className='d-flex flex-row gap-3'>
                                  <div><strong>Date: </strong>{data.date && moment(data.date).format("DD-MM-YYYY")}</div>
                                  {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                                </div>
                                <strong>Patient Name: </strong>{data.patientId}
                              </div>
                              <div className='d-flex justify-content-between col-4'>
                                <div>
                                  <strong>Room No: </strong>
                                  {data.assignedBed && data.assignedBed.split('-')[0]}
                                </div>
                                <div>
                                  <strong className='pe-3'>AssignedBed:</strong>
                                  {data.assignedBed && data.assignedBed.split('-')[1]}
                                </div>
                              </div>
                            </div>

                            { /* Previous Code Start */}
                            {/* <div className='mt-2'>
                              <table style={{ width: '100%', fontSize: '14px' }}>
                                <thead >
                                  <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign: 'center', fontWeight: '600' }}>
                                    <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                                    <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                                    <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                                    <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                                    <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                                  </tr>
                                </thead>
                                <tbody style={{ border: '1px solid #C9C9C9' }}>
                                  <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftAIncharge}</th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftATime}</th>
                                    <th style={{ width: '15%', paddingLeft: '20px' }}>
                                      <div className="form-check " style={{ height: '15px' }}>
                                        <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'low'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                        <label className="" style={{ fontSize: '16px', fontWeight: 'normal', color: 'black' }} >
                                          Low
                                        </label>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftBIncharge}</th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftBTime}</th>
                                    <th style={{ width: '15%', paddingLeft: '20px' }}>
                                      <div className="form-check " style={{ height: '15px' }}>
                                        <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'moderate'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                        <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                          Moderate
                                        </label>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr style={{ height: '23px', fontWeight: 'normal', fontSize: '13.5px' }}>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9', fontWeight: 'normal', paddingLeft: '5px' }}>{data && data.shiftCIncharge}</th>
                                    <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                                    <th style={{ width: '10%', border: '1px solid #C9C9C9', fontWeight: 'normal', textAlign: 'center' }}>{data && data.shiftCTime}</th>
                                    <th style={{ width: '15%', paddingLeft: '20px' }}>
                                      <div className="form-check " style={{ height: '15px' }}>
                                        <input className="form-check-input" type="checkbox" disabled checked={data.suicideRisk === 'high'} style={{ padding: '7px', border: '1.2px solid black' }} />
                                        <label className="" style={{ fontSize: '16px', fontWeight: 'normal' }} >
                                          High
                                        </label>
                                      </div>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                            { /* Previous Code End */}
                            <div style={{ border: '1px solid #C9C9C9' }}>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                  <div>
                                    CHECK PRECAUTIONS:
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Every 15 Minutes
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      1:1
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Eyesight
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Agitated Behavior
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Arson
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Assault
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Fall
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Detox
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Elopement
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Homicide
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Sexual Acting Out
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Self Harm
                                    </label>
                                  </div>
                                  <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Other {`${'('}`}Describe{`${'):'}`}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>LOCATION CODES</div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                      <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {item.key}-{item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>ACTIVITY CODES</div>
                              </div>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                  {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                    <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {inx + 1}.{item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className='' style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 48 && ind <= 71 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 72 && ind <= 95 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                            </div>
                            {/* <div style={{ width: '100%', backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                              <div style={{ width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600' }}>
                                  <div style={{ width: '25%' }}>Location</div>
                                  <div style={{ width: '25%' }}>Code</div>
                                  <div style={{ width: '25%' }}>Location</div>
                                  <div style={{ width: '25%' }}>Code</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>PT Room</div>
                                  <div style={{ width: '25%' }}>R</div>
                                  <div style={{ width: '25%' }}>Conf Rm</div>
                                  <div style={{ width: '25%' }}>C</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>Day Room</div>
                                  <div style={{ width: '25%' }}>DR</div>
                                  <div style={{ width: '25%' }}>Mid Line</div>
                                  <div style={{ width: '25%' }}>L</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>Dining RM</div>
                                  <div style={{ width: '25%' }}>D</div>
                                  <div style={{ width: '25%' }}>Quiet RM</div>
                                  <div style={{ width: '25%' }}>QR</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>Nurses ST.</div>
                                  <div style={{ width: '25%' }}>N</div>
                                  <div style={{ width: '25%' }}>Shower</div>
                                  <div style={{ width: '25%' }}>S</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>Telephone</div>
                                  <div style={{ width: '25%' }}>T</div>
                                  <div style={{ width: '25%' }}>Seclusion</div>
                                  <div style={{ width: '25%' }}>SC</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '25%' }}>BathRoom</div>
                                  <div style={{ width: '25%' }}>B</div>
                                  <div style={{ width: '25%' }}>Hall</div>
                                  <div style={{ width: '25%' }}>H</div>
                                </div>
                              </div>
                              <div style={{ width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                                  <div style={{ width: '100%', fontWeight: '600' }}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>1. Sleeping (3 respirations observed)</div>
                                  <div style={{ width: '44%' }}>7. Oppositional</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>2. Calm, interacting appropriately</div>
                                  <div style={{ width: '44%' }}>8. Bizarre behavior</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>3. Withdrawn/Quiet</div>
                                  <div style={{ width: '44%' }}>9. Shouting/loud/agitated</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>4. Crying, tearful</div>
                                  <div style={{ width: '44%' }}>10. Out of control, aggressive</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>5. Anxious, restless</div>
                                  <div style={{ width: '44%' }}></div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ width: '56%' }}>6. Elated, hyperactive, manic</div>
                                  <div style={{ width: '44%' }}></div>
                                </div>
                              </div>
                            </div>
                            <article style={{ width: '100%' }} className=''>
                              <div style={{ width: '100%', fontSize: '13px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                </div>
                              </div>
                              <div style={{ width: '100%', fontSize: '13px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                </div>
                              </div>
                              <div style={{ width: '100%', fontSize: '13px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                </div>
                              </div>
                              <div style={{ width: '100%', fontSize: '13px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                </div>
                              </div>
                              <div style={{ width: '100%', fontSize: '13px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                                  <div style={{ width: '100%', border: '1px solid #C9C9C9', paddingBottom: '10px' }}>
                                    <div style={{ padding: '1px 7px', fontWeight: '600' }}>PRECAUTIONS MEANING: </div>
                                    <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            SAO: SEXUALLY ACTING OUT
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            ASP: ACTIVE SUICIDE PREC.
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            FP: FALL PREC.
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            GSP: GEN SAFETY PREC.
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            DETOX-ETOH
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            DETOX-DRUG
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            AP: ASSAULT PREC.
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            EP: ELOPEMENT PREC
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            1:1: ONE TO ONE
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            OBSERVATION
                                          </label>
                                        </div>
                                        <div className="form-check " style={{ height: '11px' }}>
                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                            SZ: SEIZURE PREC.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                </div>
                              </div>
                            </article> */}

                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                              </div>
                              {[1, 2, 3, 4].map((t: any, indx: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                </div>
                              ))}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                              </div>
                            </div>
                          </>
                          }
                        </section>}
                        {/* Single Report Time PM View End  */}
                        {/* Notes Table Start */}
                        {/* <section className={`px-5 ${filterdata.length > 0 ? 'py-3': 'py-0' }`}>
                         {
                           (tableData && filterdata.length>0) && 
                           <>
                             <div className='mt-2' style={{width: '100%', fontSize: '13px'}}>
                               <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                 <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time</div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                 <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                               </div>
                             </div>
                             <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                               <div style={{ width: '50%', fontSize: '14px' }}>
                                 { filterdata && filterdata?.map((item: any, inx: number) => {
                                   const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                   return inx >=0 && inx <= ((filterdata.length/2)-1) && (
                                     <>
                                       <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                         <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                       </div>
                                     </>  
                                    )
                                   })
                                 }
                               </div>                    
                               <div style={{ width: '50%', fontSize: '14px' }}>
                                 { filterdata && filterdata?.map((item: any, inx: number) => {
                                   const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                   return inx >= (filterdata.length/2) && inx <= (filterdata.length-1) && (
                                     <>
                                       <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                         <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                       </div>
                                     </>  
                                    )
                                   })
                                 }
                               </div>
                             </div>
                           </> 
                         }
                       </section > */}
                        {/* Notes Table End */}
                      </div>
                    </>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          }
          )
        }
      </div>
      {/* Multi Report Summary&Details View End */}

    </div>
  )
}

export default MultipleReportView