import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogContentText, IconButton, MenuItem } from '@mui/material';
import Loader from "../../components/loader/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getAllBedAssign
} from "../../slices/bedAssign/thunk";
import { getAllBed } from "../../slices/patientAssign/thunk";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardFooter,
  Badge,
  Button
} from "reactstrap";
import { faCircle, faPencil } from "@fortawesome/free-solid-svg-icons";
import "./bedassign.css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Table,
} from "reactstrap";
import { Pagination } from "react-bootstrap";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { baseURL, successCode } from "../../configuration/url";
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";
import { toast } from "react-toastify";
import "../bedAssign/bedassign.css"
import { Search } from "@mui/icons-material";
import { getPatientDropdown, getInPatientDropdown, getAllRole, getDeviceDropdown } from "../../slices/thunk";
import moment from "moment";
import { FaQrcode } from "react-icons/fa";
import { BrowserMultiFormatReader } from "@zxing/library";
import { closeErrorPopup } from "../../slices/staff/reducer";
interface FormData {
  id: string;
  bedId: string;
  pid: string;
  orgId: string;
  deviceId: any;
  doctorName: any;
}
interface Bed {
  roomNoStart: string;
  roomNoEnd: string;
  bedNo: string;
  oddOrEven: string,
  organization: string,
}
type BedAssign = {
  roomNo: string;
  bedNo: string;
  pid: string;
  patientName: string;
};
type BedAssigndata = {
  roomNo: string;
  bedNo: string;
  pid: string;
  bedId: string
  patientName: string;
};
const BedAssign: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
  const [bedId, setBedId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [value, setValue] = React.useState('1');
  const [deviceId, setDeviceId] = useState('')
  const { deviceId: options, } = useSelector((state: any) => state.Patient);
  let increment = 1;
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event: any, newValue: any) => {
    setSearchTerm("");
    setValue(newValue);
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  };
  const handleClose = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };
  const closePopup = () => {
    dispatch(closeErrorPopup())
  }
  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }
  const { loading } = useSelector(
    (state: any) => state.BedAssign
  );

  const { bedAssignData = [], }: { bedAssignData: BedAssigndata[] } = useSelector(
    (state: any) => state.BedAssign
  );
  const { patientAssignData = [] }: { patientAssignData: BedAssign[] } = useSelector(
    (state: any) => state.PatientAssign
  );
  const { organization } = useSelector((state: any) => state.Login);
  const { patientData, inpatientDropdown } = useSelector((state: any) => state.Patient);
  const selectedPatientId = patientData?.id;
  const [selectedStaff, setSelectedStaff] = useState("");
  const { roleList } = useSelector((state: any) => state.PSConfig)
  const username = useSelector((state: any) => state.Login.userDetails);

  const [bedAssignedData, setBedAssignedData] = useState<FormData>({
    id: "",
    bedId: bedAssignData[0]?.bedId,
    pid: selectedPatientId || "",
    orgId: organization,
    deviceId: '',
    doctorName: ''
  });

  let [newAssignedBy, setAssignedBy] = useState<string | null>(null);
  const crtime: any = localStorage.getItem('LTime');
  const formattedDate = moment(crtime).format("YYYYMMDD")
  const handlePatientChange = (selectedPatientId: string) => {
    setBedAssignedData((prevData) => ({ ...prevData, pid: selectedPatientId }));
  };
  const [show, setShow] = useState(false);
  const [scanning, setScanning] = useState(false);

  const handleQrClick = () => {
    if (options.length < 1) return;
    setShow(true);
    setScanning(!scanning);
  }

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getPatientDropdown(dispatch, organization);
    getInPatientDropdown(dispatch, organization)
    fetchPatientsandBedAssign();
    setAssignedBy(window.localStorage.getItem("LoginData"));
  }, [organization]);

  const [formData, setFormData] = useState<Bed>({
    roomNoStart: "",
    roomNoEnd: "",
    bedNo: "",
    oddOrEven: "",
    organization: organization,
  });
  const handleSave = async () => {
    let nameErrors = {
      patientName: !bedAssignedData.pid,
      deviceId: !deviceId,

    }
    setNameError(nameErrors)
    const hasErrors = Object.values(nameErrors).some(error => error);
    if (hasErrors) {
      toast.error("Please Fill Bed Required Field")
      return;
    }
    const requestBody = {
      bedId: bedId,
      deviceId: deviceId,
      pid: bedAssignedData.pid,
      assignedBy: username,
      admitDate: formattedDate,
      doctorName: selectedStaff,
    };
    try {
      const response = await axios.post(
        `${baseURL}/Q15Bed/assign`,
        requestBody
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        toast.success(response.data.message.description)
        setEditModal(false);
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization)
      } else {
        console.error("Error:", response.data.message);
        toast.error(response.data.message.description)
      }
    } catch (error) {
      console.error("API Request Error:", error);
      toast.error("An error occurred. Please check console for details.")
    } finally {
      setEditModal(false);
    }
  };
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);
  const [bed, setBed] = useState('')
  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();
  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            let newDeviceId = "";
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption) {
              setDeviceId(scannedOption.deviceId)
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Scanned device ID not found in the options.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };

  const handleClick = (selectedBed: any) => {
    if (selectedBed) {
      const bedNo = selectedBed.roomNo + "-" + selectedBed.bedNo;
      setBed(bedNo)
      const bedAssignId = selectedBed.id || " ";
      setBedId(bedAssignId);
      setBedAssignedData({
        id: selectedBed.id,
        bedId: selectedBed.bedId,
        pid: selectedBed.pid,
        orgId: selectedBed.orgId,
        deviceId: deviceId,
        doctorName: selectedStaff,

      });
      setEditModal(true);
    } else {
      console.error("Invalid Data:", selectedBed);
    }
  };

  const fetchPatientsandBedAssign = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const [perPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / perPage);
  useEffect(() => {
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);
  useEffect(() => {
    setNameError({ ...nameError, deviceId: false, selectedStaff: false })
  }, [])

  const handleSaveBed = async () => {
    if (!formData.bedNo) {
      toast.warn("Please fill All The Fields")
      return;
    }
    const requestBody = {
      roomNoStart: formData.roomNoStart,
      roomNoEnd: formData.roomNoEnd,
      bedNo: formData.bedNo,
      oddOrEven: formData.oddOrEven,
      organization: formData.organization
    };
    try {
      const response = await axios.post(
        `${baseURL}/Q15Bed/create`,
        requestBody
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        toast.success(response.data.message.description);
        setEditModal(false);
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
      } else {
        toast.error(`Error:${response.data.message.description}`);
      }
    } catch (error) {
      toast.error("Room No and Bed No Already Exists")

    }
    setEditModal(false);
  };
  const itemsPerPage = 8;
  const [bedCurrentPage, setBedCurrentPage] = useState(1);
  const indexOfLastItem = bedCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBedAssignData =
    bedAssignData && bedAssignData?.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    getDeviceDropdown(dispatch, organization)
  }, [organization]);
  useEffect(() => {
    getAllRole(dispatch, 'Physician', organization)
  }, [dispatch, organization])
  const paginate = (pageNumber: number) => setBedCurrentPage(pageNumber);
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(bedAssignData.length / itemsPerPage);

    const pageNumbersToShow = Math.min(5, totalPages);

    let startPage: number;
    let endPage: number;

    if (totalPages <= pageNumbersToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middlePage = Math.ceil(pageNumbersToShow / 2);

      if (bedCurrentPage <= middlePage) {
        startPage = 1;
        endPage = pageNumbersToShow;
      } else if (bedCurrentPage + middlePage >= totalPages) {
        startPage = totalPages - pageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = bedCurrentPage - middlePage + 1;
        endPage = bedCurrentPage + middlePage - 1;
      }
    }
    return Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
      <Pagination.Item
        key={startPage + index}
        active={startPage + index === bedCurrentPage}
        onClick={() => paginate(startPage + index)}
      >
        {startPage + index}
      </Pagination.Item>
    ));
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredBedList = patientAndBedAssign?.filter((bedassign: any) => (
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase())
  ));
  const bedsByNumber = filteredBedList.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});

  const filteredBedList1 = patientAssignData?.filter((bedassign: any) => {
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    return bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase())
  });

  const occupaidbedsByNumber = filteredBedList1.reduce<{ [key: string]: BedAssign[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {} as { [key: string]: BedAssign[] });

  const filteredBedList2 = bedAssignData?.filter((bedassign: any) => {
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    return bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase())
  });
  const abailablebedsByNumber = filteredBedList2.reduce<{ [key: string]: BedAssign[] }>((acc, bedassign) => {
    const { roomNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {} as { [key: string]: BedAssign[] });

  return (
    <div className="container m15 p3 " style={{ width: "90%", height: 'auto' }}>
      {loading && <Loader />}
      <div className="d-flex mt-2 justify-content-between">
        <h5 style={{ top: 0 }}>Bed Details</h5>
        <div className="col-md-5 d-flex justify-content-end gap-3">
        </div>
        <div className="">
          <TextField
            label="Search Room Number"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              style: { height: '45px' },
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            fullWidth
          />
        </div>
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All Beds" value="1" />
              <Tab label="Occupied" value="2" />
              <Tab label="Available" value="3" />
            </TabList>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <TabPanel
              sx={{ padding: '0px' }}
              style={{
                flex: 1,
                maxHeight: 'calc(100vh - 180px)', // Adjust based on your layout
                overflowY: 'auto',
                padding: '0',
                transition: 'max-height 0.3s ease' // Smooth transition for resizing
              }}
              value="1"
            >
              <div>
                <div className="container" >
                  <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                    {Object.entries(bedsByNumber).length > 0 ? (Object.entries(bedsByNumber).map(([bedNo, assignments]) => (
                      <Row key={bedNo}
                        style={{ display: "flex", width: "100%" }}
                      // style={{ display: "flex", flexWrap: "wrap", justifyContent: bedNo === '1' ? 'space-evenly' : 'flex-start', marginBottom: "40px" }}
                      >
                        {assignments.map((bedassign, index) => (
                          bedassign.patientName !== null ? <>
                            <Col key={bedassign.id} style={{ flex: 0, padding: 0, width: "33.3%" }}>
                              <div className="bed-assignment-box">
                                <Card
                                  className="mb-3"
                                  color="danger"
                                  outline
                                  style={{
                                    width: "92px",
                                    height: "70px",
                                    margin: "5px",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <CardBody
                                    key={index}
                                    style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                  >
                                    <CardTitle tag="h6">
                                      <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                      <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                    </CardTitle>
                                    <CardSubtitle tag="h6" className=" text-muted">
                                      <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                      <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                    </CardSubtitle>
                                  </CardBody>
                                  <CardFooter style={{
                                    padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                    background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                  }}>
                                    <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{bedassign.patientName}</span>
                                  </CardFooter>
                                </Card>
                              </div>
                            </Col>
                          </> : <>
                            <Col key={index} style={{ flex: 0, padding: 0, width: "33.3%" }}>
                              <div className="bed-assignment-box">
                                <Card
                                  className="mb-3"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "92px",
                                    height: "70px",
                                    margin: "5px",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <CardBody
                                    key={index}
                                    onClick={() => handleClick(bedassign)}
                                    style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                  >
                                    <CardTitle tag="h6">
                                      <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                      <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                    </CardTitle>
                                    <CardSubtitle tag="h6" className="text-muted ">
                                      <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                      <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                    </CardSubtitle>
                                  </CardBody>

                                  <CardFooter style={{
                                    padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '33px',
                                    background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                  }}>

                                    <Badge
                                      style={{ fontSize: '10px' }}
                                      color={bedassign.patientName ? "danger" : "success"}
                                      tag="h6"
                                    >
                                      {bedassign.patientName && bedassign.patientName !== '' ? "Not Available" : "Available"}
                                    </Badge>
                                  </CardFooter>
                                </Card>
                              </div>

                            </Col>

                          </>
                        ))}
                      </Row>
                    ))
                    ) : (
                      <div
                        className=""
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        <p>No Beds Available</p>
                      </div>
                    )
                    }
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value="2"
              style={{
                flex: 1,
                maxHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
                padding: '0',
                transition: 'max-height 0.3s ease'
              }}
            >
              <div className="container m15 p3" >
                <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                  {Object.entries(occupaidbedsByNumber).length > 0 ? Object.entries(occupaidbedsByNumber).map(([bedNo, assignments]) => (
                    <Row key={bedNo} >
                      {assignments.map((patientassign, index) => (
                        <Col key={index} style={{ flex: 0, padding: 0 }}>
                          <div className="bed-assignment-box">
                            <Card
                              className="mb-3"
                              color="danger"
                              outline
                              style={{
                                width: "92px",
                                height: "70px",
                                margin: "5px",
                                justifyContent: "flex-start",
                              }}
                            >
                              <CardBody
                                key={index}
                                style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                              >
                                <CardTitle tag="h6">
                                  <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                  <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{patientassign.roomNo}</span>
                                </CardTitle>
                                <CardSubtitle tag="h6" className="text-muted ">
                                  <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                  <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{patientassign.bedNo}</span>
                                </CardSubtitle>
                              </CardBody>

                              <CardFooter style={{
                                padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                background: patientassign.bedNo === '01' ? '#f8b19570' : (patientassign.bedNo === '02' ? '#c06c8470' : (patientassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                              }}>
                                <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{patientassign.patientName}</span>
                              </CardFooter>
                            </Card>
                          </div>
                        </Col>
                      ))}

                    </Row>
                  ))
                    :
                    <div
                      className=""
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      No Bed Occupied
                    </div>
                  }
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value="3"
              style={{
                flex: 1,
                maxHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
                padding: '0',
                transition: 'max-height 0.3s ease'
              }}
            >
              <div>
                <div className="container " >
                  <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                    {Object.entries(abailablebedsByNumber).length > 0 ? (Object.entries(abailablebedsByNumber).map(([bedNo, assignments]) => (
                      <Row key={bedNo}
                        className="px-1"
                        style={{ display: "flex", width: "100%" }}
                      >
                        {assignments.map((bedassign, index) => (
                          <Col key={index} style={{ flex: 0, padding: 0, width: "33.3%" }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="primary"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  onClick={() => handleClick(bedassign)}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className="text-muted ">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>

                                <CardFooter style={{
                                  padding: '2px 0.6rem 2px 13px', position: 'relative', top: '-13px', height: '33px',
                                  background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>
                                  <Badge
                                    style={{ fontSize: '10px' }}
                                    color={bedassign.pid ? "danger" : "success"}
                                    tag="h4"
                                  >
                                    {bedassign.pid ? "Not Available" : "Available"}
                                  </Badge>

                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        ))}

                      </Row>
                    ))
                    ) : (
                      <div
                        className=""
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        <p>No Bed Available</p>
                      </div>
                    )
                    }
                  </div></div>

              </div>
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
      <Modal isOpen={editModal} style={{ width: '100%' }} sx={{ innerWidth: '100%', outerWidth: '100%' }} toggle={() => setEditModal(false)}>
        <ModalHeader toggle={() => {
          setEditModal(false); setBedAssignedData({
            id: "",
            bedId: "",
            pid: "",
            orgId: organization,
            deviceId: '',
            doctorName: ''
          }); setSelectedStaff(''); setDeviceId(''); setNameError({})

        }}>Assign Bed For Patient</ModalHeader>
        <ModalBody>
          <div>
            <div>
              <p style={{ fontWeight: "bold" }}>Selected bed: {bed}</p>
            </div>
            <div className="row w-100 mb-4">
              <div className="col-md-12">
                <Autocomplete
                  className="m-0"
                  id="patientName"
                  options={inpatientDropdown?.filter(
                    (patient: any) => patient?.name
                  )}
                  getOptionLabel={(option: any) => option.name}
                  value={inpatientDropdown?.find(
                    (patient: any) => patient?.id === bedAssignedData?.pid
                  )}
                  onChange={(event: any, value: any) => {
                    if (value !== null) {
                      handlePatientChange(value.id);
                      setNameError({ ...nameError, patientName: false });
                    } else {
                      setNameError({ ...nameError, patientName: true });
                    }
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Patient"
                      placeholder="Select Patient"
                      margin="none"
                      required
                      fullWidth
                      error={!!nameError.patientName}
                      helperText={nameError.patientName ? "Patient Name is required" : ""}
                    />
                  )}
                  style={{ marginTop: '-10px' }}
                />
              </div>
            </div>

          </div>
          <div className="row w-100 mt-2">
            <div className='col-md-6 mb-2'>
              <TextField
                label='Device-Id'
                value={deviceId}
                onChange={(event) => { setDeviceId(event.target.value); setNameError({ ...nameError, deviceId: false }) }}
                required
                error={!!nameError.deviceId}
                helperText={nameError.deviceId ? 'DeviceId is Required' : ''}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <FaQrcode
                      className="position-absolute bottom-0 m-3"
                      onClick={handleQrClick}
                      role="button"
                      style={{
                        fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0
                      }}
                    />
                  ),

                }}
                select
                SelectProps={{
                  IconComponent: () => null,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '300px',
                      },
                    },
                  },
                }}
              >
                {options?.length > 0 ? options?.map((option: any) => (
                  <MenuItem key={option?.id} value={option?.deviceId}>
                    {option?.deviceId}
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{
                        fontSize: "12px",
                        color: !option?.status ? '#5FB477' : '#EF6868',
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: '10px',
                        marginBottom: '1px'
                      }}
                    />
                  </MenuItem>
                )) :
                  <MenuItem>
                    No Devices Data
                  </MenuItem>
                }
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                label="Select Physician"
                value={selectedStaff}
                onChange={(e) => { setSelectedStaff(e.target.value) }}
                fullWidth
                select
                style={{ marginTop: '1px' }}
              >
                {roleList?.length > 0 ? (
                  roleList?.map((staff: any) => (
                    <MenuItem
                      key={staff.id}
                      value={`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                    >
                      {`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Staff Available</MenuItem>
                )}
              </TextField>
            </div>
          </div>
        </ModalBody>


        <ModalFooter>
          <Button
            style={{
              color: "#000",
              backgroundColor: "#94a0b7",
              fontWeight: "bold",
            }}
            severity="secondary"
            className="btn btn-secondary"
            onClick={() => {
              setEditModal(false); setBedAssignedData({
                id: "",
                bedId: "",
                pid: "",
                orgId: organization,
                deviceId: "",
                doctorName: ''
              }); setSelectedStaff(''); setDeviceId(''); setNameError({})
            }}
          >
            Cancel
          </Button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={show} >
        <ModalHeader closeButton>
          Scanning
        </ModalHeader>

        <ModalBody>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ marginRight: '10px' }}
          >{'Close'}</Button>
          <Button
            style={{ backgroundColor: '#0f3995' }}
            onClick={closeModalAndRec}
          >
            {scanning ? "Stop Scanning" : "Start Scanning"}
          </Button>
        </ModalFooter>

      </Modal>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered>
        <ModalHeader toggle={() => setShowModal(false)}>
          <h3>Bed Assign Details</h3>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="form-control">
              <label
                htmlFor="roomNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '30%' }}
              >
                Start Room No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="roomNo"
                name="roomNo"
                placeholder="Enter No"
                value={formData.roomNoStart}
                onChange={(e) => setFormData({ ...formData, roomNoStart: e.target.value })}
              ></input>
              <label style={{ width: '2%' }} />
              <label
                htmlFor="bedNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '28%' }}
              >
                End Room No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="bedNo"
                name="bedNo"
                placeholder="Enter No"
                value={formData.roomNoEnd}
                onChange={(e) => setFormData({ ...formData, roomNoEnd: e.target.value })}
              ></input>
              <div style={{ marginBottom: '15px' }}></div>
              <label
                htmlFor="roomNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '30%' }}
              >
                Bed No:
              </label>
              <input style={{ width: '20%' }}
                type="text"
                id="roomNo"
                name="roomNo"
                placeholder="Enter No"
                value={formData.bedNo}
                onChange={(e) => setFormData({ ...formData, bedNo: e.target.value })}
              ></input>
              <label style={{ width: '2%' }} />
              <label
                htmlFor="bedNo"
                className="floating-label"
                style={{ fontWeight: "bold", width: '28%' }}
              >
                Type:
              </label>
              <select id="bedNo" style={{ width: '20%', height: '45px', border: '1px solid black', borderRadius: '5px' }} name="bedNo" value={formData.oddOrEven} onChange={(e) => setFormData({ ...formData, oddOrEven: e.target.value })} >
                <option value="" >Select</option>
                <option value="odd" >Odd</option>
                <option value="even" >Even</option>
              </select>
              <Pagination>
                <Pagination.Prev
                  onClick={() => setBedCurrentPage(bedCurrentPage - 1)}
                  disabled={bedCurrentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                  onClick={() => setBedCurrentPage(bedCurrentPage + 1)}
                  disabled={bedCurrentPage === Math.ceil(bedAssignData.length / itemsPerPage)}
                />
              </Pagination>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th scope="col" className="text-center">S.No</th>
                    <th scope="col" className="text-center">Room No</th>
                    <th scope="col" className="text-center">Bed No</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentBedAssignData) && currentBedAssignData.length > 0 && (
                    currentBedAssignData.map((bedassign: any, index: number) => (
                      <tr key={parseInt(bedassign.roomNo)}>
                        <td>{increment++}</td>
                        <td>{bedassign.roomNo}</td>
                        <td>{bedassign.bedNo}</td>
                      </tr>
                    )))}
                </tbody>
              </Table>
              <ModalFooter style={{ position: 'relative', top: '12px' }}>
                <Button color="info" onClick={handleSaveBed}>
                  Save Changes
                </Button>{" "}
                <Button color="danger" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>

  );
};

export default BedAssign;