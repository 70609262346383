import React, { useEffect, useState } from "react";
import "./staffconfig.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { fetchStaffDropDownOneHour, getAllRNIncharge, getAllSocialWorkers } from "../../slices/thunk";
import ErrorPopup from "../../components/errorPopup";
import { closeErrorPopup } from "../../slices/staffConfiguration/reducer";
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { baseURL, successCode } from "../../configuration/url";
import { Box, Typography } from "@mui/material";
import { authorize } from "../../helpers/common";
import DateBox from "../../components/calendar/dateBox";
import moment from "moment";
import TabViewStaff from "./TabViewStaff";
import { IoMdArrowBack } from "react-icons/io";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", backgroundColor: "#f2f5f9" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const OneHourStaffConfig = () => {

  const dispatch = useDispatch<any>();
  const { loading, isOpen, errorMsg } = useSelector(
    (state: any) => state.PSConfig
  );
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const { organization } = useSelector((state: any) => state.Login);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedDate1, setSelectedDate1] = useState<any>();
  const { header1 } = authorize();
  const [Loading, setLoading] = useState<boolean>(false)

  const [value, setValue] = React.useState(0);
  const [noOfShift, setNoOfShift] = useState<any>(0);
  const [datechangecheck, setdatechangecheck] = useState<boolean>(false)
  const [nextDate, setNextDate] = useState<any>('')
  const handleChange = (newValue: number) => {
    setValue(newValue);
    setdatechangecheck(!datechangecheck);
  };
  const [getAllShiftData, setGetAllShiftData] = useState<any[]>([]);

  const { orgData } = useSelector((state: any) => state.Org);
  const [previousCheck, setPreviousCheck] = useState<boolean>(false)
  const [checkdate, setCheckdate] = useState<boolean>(false)
  const [nextDaydate, setNextDayDate] = useState<any>('')


  const closePopup = () => {
    dispatch(closeErrorPopup());
  };

  useEffect(() => {
    if (renderTabContent() !== "General" && renderTabContent() !== "No Content Available" && renderTabContent() !== "Previous" && checkdate) {
      setCheckdate(false)
    }
  }, [previousCheck, value])
  useEffect(() => {
    if (!selectedDate) return
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
    const tabSlotTime = previousCheck ? moment(nextDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
    setSelectedDate(moment(tabSlotTime).format('YYYY-MM-DD'))
  }, [datechangecheck])
  useEffect(() => {
    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    setNoOfShift(numShift);

  }, [dispatch, selectedDate]);

  const handlestartTime = () => {
    const crtime: any = localStorage.getItem("LTime");
    if (crtime) {
      const formattedDate = moment(crtime).format("YYYY-MM-DD");
      const currentTime = moment(crtime).format('HH:mm')
      const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
      const CurrTime: any = moment(`1970-01-01T${currentTime}`)
      if (shiftStartTime !== CurrTime) {
        if (CurrTime.isAfter(OrgStartTime)) {
          setSelectedDate(formattedDate);
          setSelectedDate1(formattedDate)
        } else {
          if (!formattedDate) return;
          const validDate = moment(formattedDate);
          if (validDate.isValid()) {
            const nextDay = validDate.clone().subtract(1, "days");
            setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
            setSelectedDate1(moment(nextDay)?.format('YYYY-MM-DD'));
          } else {
            console.error("Invalid date:", formattedDate);
          }
        }
      } else {
        setSelectedDate(formattedDate);
        setSelectedDate1(formattedDate);
      }
    }
  };
  useEffect(() => {
    handlestartTime();
  }, []);

  useEffect(() => {
    saveOrganization(dispatch);
    getAllRNIncharge(dispatch, "Registered Nurse", organization);
    getAllSocialWorkers(dispatch, "Social Worker", organization);
    getOrgByID(dispatch, organization);
  }, [dispatch, selectedDate]);
  useEffect(() => {
    if (!selectedDate) return
    const ShiftName = renderTabContent()
    const tabShiftName = ShiftName === "Previous" ? renderShiftTab() : ShiftName
    fetchStaffDropDownOneHour(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, tabShiftName)
  }, [selectedDate, organization, value])
  function addHours(time: any, hours: any) {
    const baseTime = new Date(`2000-01-01T${time}`);
    const newTime = new Date(baseTime.getTime() + hours * 60 * 60 * 1000);
    return `${newTime.getHours().toString().padStart(2, "0")}:${newTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  const [roomNumbers, setRoomNumbers] = useState(new Array<any>());
  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }

    return 24 / duration; // Return the number of shifts
  };
  useEffect(() => {

    fetch(`${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 })
      .then((response) => response.json())
      .then((data) => {
        const roomBedCombinations = data.data?.map((item: any) => {
          let bedNo = item.bedNo;
          if (bedNo === "01") {
            bedNo = "A";
          } else if (bedNo === "02") {
            bedNo = "B";
          } else if (bedNo === "03") {
            bedNo = "C";
          }
          return `${item.roomNo} - ${bedNo}`;
        });
        setRoomNumbers(roomBedCombinations);
      })
      .catch((error) => console.error("Error fetching room numbers:", error));
  }, []);


  const handleDateChange = (e: any) => {
    try {
      const newDate = new Date(e.target.value);
      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "2-digit",
        year: "numeric",
      };
      setSelectedDate(newDate);

      getByOrganizationData();
    } catch (error) {
      alert(error);
    }
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  };
  const renderShiftTab = () => {
    switch (noOfShift - 1) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  }
  const getByOrganizationData = async () => {
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')

    const ShiftName = renderTabContent()
    const tabShiftName = previousCheck ? renderShiftTab() : ShiftName
    const tabSlotTime = previousCheck ? moment(nextDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")

    HttpLogin.axios()
      .get(
         `/api/ohsConfig/getShiftRecordByShiftName?date=${tabSlotTime}&organization=${organization}&shiftName=${tabShiftName}`,
        { headers: header1 }
      )
      .then((response) => {
        if (response.data.message.code === "MHC - 0200") {
          setGetAllShiftData(response.data.data);
          setPreviousCheck(false)
          setPreviousCheck(false)
        } else {
          setGetAllShiftData([]);
          setPreviousCheck(false)
          setPreviousCheck(false)
        }
      }).catch((err) => {
        console.error(err, 'Error on getByDateAndOrganization API')
        setPreviousCheck(false)
        setPreviousCheck(false)
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/sensor/getAllByorgId/${organization}`,
          { headers: header1 }
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, "days");
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      setNextDayDate(moment(nextDay)?.format('YYYY-MM-DD'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().subtract(1, 'days');
      setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      setNextDayDate(moment(nextDay)?.format('YYYY-MM-DD'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().subtract(1, 'days');
      setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  useEffect(() => {
    getByOrganizationData();
  }, [value, selectedDate])

  const [shifts, setShifts] = useState<any>([]);
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    for (let i = 0; i < totalShifts; i++) {
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      );
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);
      const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });
      startTime = endTime;
    }
    setShifts(shifts);
  };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
  }, [orgData, noOfShift])
  const [dateRange, setDateRange] = useState<any>()
  useEffect(() => {
    if (!shifts) return
    if (previousCheck) {
      setDateRange(shifts[noOfShift - 1])
    } else if (renderTabContent() === "General") {
      setDateRange(shifts[0])
    } else {
      setDateRange(shifts[value])
    }
  }, [shifts, value, previousCheck])
  return (
    <React.Fragment>
      {Loading && <Loader />}
      {Loading && <Loader />}
      {loading && <Loader />}
      <h5 style={{ padding: '12px' }}>1-Hour Staff Assignment</h5>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          padding: "10px",
          width: "90%",
          position: "relative",
          left: "66px",
        }}
      >
        <DateBox
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleDateChange={handleDateChange}
          countCheck={null}
          dateRange={dateRange}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "15%",
            backgroundColor: "#eaf2fa",
            borderRight: "1px groove #ccd2d8",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <button
              className={`nav-link ${value === 7 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls=""
              aria-selected={value === 7}
              onClick={() => { handleChange(7); setPreviousCheck(true); setCheckdate(true) }}
              style={{
                backgroundColor: value === 7 ? "#cddcfa" : "",
                color: value === 7 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              <IoMdArrowBack style={{ fontSize: '18px', paddingBottom: '2px' }} />
              Previous
            </button>
            {Array.from({ length: noOfShift }).map((_, index) => (
              <button
                className={`nav-link ${value === index ? "active" : ""}`}
                id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                type="button"
                role="tab"
                aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                aria-selected={value === index}
                onClick={() => handleChange(index)}
                style={{
                  backgroundColor: value === index ? "#cddcfa" : "",
                  color: value === index ? "" : "black",
                  width: "91%",
                  height: "40px",
                  borderRadius: "4px",
                }}
              >
                {`Shift-${String.fromCharCode(65 + index)}`}
              </button>
            ))}
            <button
              className={`nav-link ${value === 6 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls="Shift-A"
              aria-selected={value === 6}
              onClick={() => handleChange(6)}
              style={{
                backgroundColor: value === 6 ? "#cddcfa" : "",
                color: value === 6 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              General
            </button>
          </div>
        </div>
        {Array.from({ length: noOfShift }).map((_, index) => (
          <TabPanel value={value} index={index}>
            <TabViewStaff getAllShiftData={getAllShiftData} selectedValue={value} selectedDate={selectedDate} getByOrganizationData={getByOrganizationData} noOfShift={noOfShift} />
          </TabPanel>
        ))}
        <TabPanel value={value} index={6}>
          <TabViewStaff getAllShiftData={getAllShiftData} selectedValue={value} selectedDate={selectedDate} getByOrganizationData={getByOrganizationData} noOfShift={noOfShift} />
        </TabPanel>
        <TabPanel value={value} index={7}><TabViewStaff getAllShiftData={getAllShiftData} selectedValue={value} selectedDate={selectedDate} getByOrganizationData={getByOrganizationData} noOfShift={noOfShift} />
        </TabPanel>


      </div>
      <ErrorPopup closePopup={closePopup} errorMsg={errorMsg} open={isOpen} />
    </React.Fragment>
  );
};

export default OneHourStaffConfig;
