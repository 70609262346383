import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "primereact/button";
import { Autocomplete, TextField, InputAdornment, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { baseURL, successCode } from "../../configuration/url";

interface SirenCreationProps {
  isOpen: boolean;
  CloseModal: () => void;
  orgID: string;
  sirenData?: any;
  dropdownData: { value: string; id: string }[];
  setSirenGet: any;
  sirenGet: boolean
}

interface registerInputData {
  virtualid: string | null;
  sirenName: string;
  location: string | null;
  id: string;
}

const SirenCreation: React.FC<SirenCreationProps> = ({
  isOpen,
  CloseModal,
  orgID,
  sirenData,
  dropdownData,
  setSirenGet,
  sirenGet
}) => {
  const registerData = {
    virtualid: "",
    sirenName: "",
    location: null,
    id: "",
  };

  const [inputData, setInputData] = useState<registerInputData>(registerData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});
  const [value, setValue] = useState<any>(null); 
  const [newLocation, setNewLocation] = useState<string>("");
  useEffect(() => {
    if (sirenData) {
      setInputData({
        virtualid: sirenData.virtualId,
        sirenName: sirenData.sirenName,
        id: sirenData.id,
        location: sirenData.location,
      });
      const selectedOption = dropdownData.find((item) => item.value === sirenData.location);
      setValue(selectedOption || null);
    } else {
      setInputData(registerData);
      setValue(null); 
      setNewLocation("");
      setNameError({});   
    }
  }, [sirenData, dropdownData, isOpen]);

  const handleAddLocation = async () => {
    if (!newLocation.trim()) {
      toast.error("Please enter a location to add.");
      return;
    }
    try {
      const locationExists = dropdownData.some((item) => item.value === newLocation);
      if (locationExists) {
        toast.error("Location already exists.");
        return;
      }
      const newOption = {
        id: Date.now().toString(),
        value: newLocation,
        type: "Hospital Location",
      };
      const payload = {
        dropdown: "Hospital Location",
        list: [newOption],
      };
      const response = await axios.post(`${baseURL}/dropdowns/add`, payload);
      if (response?.data?.message?.code === successCode) {
        const savedItem = {
          value: response.data.data.list[0].value,
          id: response.data.data.list[0].id,
          type: response.data.data.list[0].type,
        };
        setValue(savedItem);
        setNewLocation("");
        dropdownData.push(savedItem);
        setNameError({});
        toast.success("Location added successfully!");
      } else {
        toast.error(response?.data?.message?.description || "Failed to add location.");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message?.description || "An error occurred while adding the location.");
      } else if (error instanceof Error) {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error("An unknown error occurred while adding the location.");
      }
      console.error("Error details:", error);
    }
  };

  const handleSubmit = async () => {
    const newErrors = {
      sirenName: !inputData.sirenName,
      virtualid: !inputData.virtualid,
      location: !inputData.location,
    };
    setNameError(newErrors);
    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) {
      toast.error("Please Fill Required Fields");
      return;
    }

    try {
      const body = {
        virtualId: inputData.virtualid,
        sirenName: inputData.sirenName,
        id: !sirenData ? "" : sirenData.id,
        location: inputData.location,
        organization: orgID,
      };

      const response = await axios.post(`${baseURL}/siren/add`, body);

      if (response && response.data.message && response.data.message.code === successCode) {
        toast.success("Registered Successfully");
        CloseModal();
        setInputData(registerData);
        setValue(null);
        setNameError({});
        setSirenGet(!sirenGet)
      } else { 
        toast.warn(response.data.message.description)
      }
    } catch (error) {
      console.error("API Error: ", error);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} size="lg" centered style={{ width: "580px" }}>
        <div className="d-flex align-items-center justify-content-center m-20">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader
                toggle={() => {
                  CloseModal();
                  setValue(null); 
                  setNameError({});
                  setInputData(registerData); 
                  setNewLocation("");
                }}
              >
                {!sirenData ? "Siren Register" : "Update"}
              </ModalHeader>
              <ModalBody>
                <div className="row w-100" style={{ marginTop: "10px" }}>
                  <div className="col-md-12 mb-2">
                    <TextField
                      label="Virtual ID"
                      variant="outlined"
                      fullWidth
                      value={inputData.virtualid}
                      onChange={(e) => {
                        setInputData({ ...inputData, virtualid: e.target.value });
                        setNameError({ ...nameError, virtualid: false });
                      }}
                      error={!!nameError.virtualid}
                      // disabled={!!sirenData}  
                    />
                  </div>
                </div>
                <div className="row w-100" style={{ marginTop: "10px" }}>
                  <div className="col-md-6 mb-2">
                    <TextField
                      label="Siren Name"
                      variant="outlined"
                      fullWidth
                      value={inputData.sirenName}
                      onChange={(e) => {
                        setInputData({ ...inputData, sirenName: e.target.value });
                        setNameError({ ...nameError, sirenName: false });
                      }}
                      error={!!nameError.sirenName}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Autocomplete
                      id="location-autocomplete"
                      options={dropdownData}
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(e, v) => {
                        setValue(v || null); 
                        setInputData({ ...inputData, location: v ? v.value : null });
                        setNameError({ ...nameError, location: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Location"
                          fullWidth
                          // value={newLocation}
                          // onChange={(e) => setNewLocation(e.target.value)} 
                          error={!!nameError.location}
                          // helperText={nameError.location ? "" : ""}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   endAdornment: (
                          //     <>
                          //       {params.InputProps.endAdornment}
                          //       <InputAdornment position="end">
                          //         {(newLocation.trim() || value) && (
                          //           <IconButton onClick={handleAddLocation}>
                          //             <CheckIcon style={{ color: "#0f3995" }} />
                          //           </IconButton>
                          //         )}
                          //       </InputAdornment>
                          //     </>
                          //   ),
                          // }}
                        />
                      )}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex gap-3 justify-content-center">
                  <Button
                    label="Cancel"
                    severity="secondary"
                    style={{
                      color: "#000",
                      backgroundColor: "#94a0b7",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      CloseModal();
                      setValue(null); 
                      setNameError({});
                      setInputData(registerData); 
                      setNewLocation("");    
                    }}
                  />
                  <Button
                    label="Save Changes"
                    style={{ backgroundColor: "#0f3995", fontWeight: "bold" }}
                    onClick={handleSubmit}
                  />
                </div>
              </ModalFooter>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SirenCreation;