import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    locationLegend: [],
    activityLegend: []
}

const ReportSlice = createSlice({
    name: 'ReportData',
    initialState,
    reducers: {
        setLocationLegend (state, action) {
            state.locationLegend = action.payload
        },
        setActivityLegend (state, action) {
            state.activityLegend = action.payload
        }
    }
})

export const { setActivityLegend, setLocationLegend } = ReportSlice.actions;
export default ReportSlice.reducer;