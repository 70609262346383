import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../components/loader/Loader";
import { FaInbox } from "react-icons/fa";
import { FaCircleMinus } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { toast } from "react-toastify";
import "./q15slot.css";
import dayjs from "dayjs";
interface Shift {
  shift_name: string;
  start_time: string;
  end_time: string;
  dayShift: string;
}
const AddShiftStaff = ({
  selectedDate,
  selectedTab,
  allTeamData,
  getAllTeam,
  noOfShift,
  inCharge,
}: any) => {
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const [AllTeamData, setAllTeamData] = useState<any>([]);
  const [staffCard, setStaffCard] = useState<any>([]);
  const [openRemoveStaff, setOpenRemoveStaff] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [selectedRemove, setSelectedRemove] = useState<any>({});
  const startTime = new Date(`2000-01-01T${shiftStartTime}`);
  const [submitStaff, setSubmitStaff] = useState(false);
  const org = useSelector((state: any) => state.Login.organization);
  const endTime = new Date(startTime.getTime() + 8 * 60 * 60 * 1000);
  const { header1 } = authorize();
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [inchargeErr, setInchargeErr] = useState(false);
  const [intime, setIntime] = useState<any>("");
  const [outtime, setOuttime] = useState<any>("");
  const [outtimeRemove, setOuttimeRemove] = useState<any>("");
  let initialState = {
    value: "",
    error: false,
  };
  const [inTimeChange, setIntimeChange] = useState(initialState);
  const [outTimeChange, setOuttimeChange] = useState(initialState);

  const [intimeErr, setIntimeErr] = useState(false);
  const [outtimeErr, setOuttimeErr] = useState(false);
  const [outtimeRemoveErr, setOuttimeRemoveErr] = useState(false);

  let [incharge, setIncharge] = useState<any>("");
  const { orgData } = useSelector((state: any) => state.Org);
  const [shifts, setShifts] = useState<any>([]);
  const [openTimeChange, setOpenTimeChange] = useState<boolean>(false);
  const handleOpenTimeChange = (data: any) => {
    setOpenTimeChange(true);
    setSelectedRemove(data);
    setIntimeChange((prev: any) => ({
      ...prev,
      value: data.inTime,
      error: false,
    }));
    setOuttimeChange((prev: any) => ({
      ...prev,
      value: data.outTime,
      error: false,
    }));
  };

  const handleStaffRemove = (data: any) => {
    setSelectedRemove(data);
    setOpenRemoveStaff(true);
    setOuttimeRemove(data?.outTime);
    setOuttimeRemoveErr(false);
  };
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  };

  const renderShiftTab = () => {
    switch (noOfShift - 1) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  }
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    // Function to convert 24-hour time to 12-hour time with AM/PM
    const to12HourFormat = (date: any) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";

      // Convert hour to 12-hour format
      hours = hours % 24;
      if (hours === 0) {
        hours = '00'; // Midnight or noon case
      }

      // Add leading zero for minutes if needed
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // Return formatted time with AM/PM
      return `${hours}:${minutes} `;
    };

    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      ); // duration in hours

      // Format start and end times into 12-hour format with AM/PM
      const startTimeFormatted = to12HourFormat(startTime);
      const endTimeFormatted = to12HourFormat(endTime);

      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
        dayShift: "",
      });

      // Update the start time for the next shift
      startTime = endTime;
    }
    const convertTo24Hour = (time: string) => {
      const [hour, minuteWithPeriod] = time.split(":");
      const [minute, period] = minuteWithPeriod.split(" ");
      let hour24 = parseInt(hour);

      // Handle AM/PM conversion
      if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
      if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

      return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
    };

    // Function to determine if a shift crosses midnight and should have +1 added
    const addDayShift = (shift: Shift, addShift: boolean): Shift => {
      const startInMinutes = convertTo24Hour(shift.start_time);
      const endInMinutes = convertTo24Hour(shift.end_time);

      // If the end time is earlier than the start time, it means it crosses midnight
      if (endInMinutes < startInMinutes) {
        return { ...shift, dayShift: "+1" };
      }

      // If previous shift had +1, add +1 to the current shift
      if (addShift) {
        return { ...shift, dayShift: "+1" };
      }

      // No need for +1 if the shift doesn't cross midnight and no previous shift had +1
      return { ...shift, dayShift: "" };
    };
    // A flag to track if we should start adding +1
    let addShift = false;

    // Apply the dayShift logic to each shift
    const updatedShifts = shifts.map((shift: any) => {
      // If we encounter a shift that crosses midnight, set addShift to true
      if (
        !addShift &&
        convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
      ) {
        addShift = true;
      }
      return addDayShift(shift, addShift);
    });
    setShifts(updatedShifts);
  };
  // const generateShifts = (
  //   startTimeStr: any,
  //   duration: any,
  //   totalShifts: any
  // ) => {
  //   const shifts = [];

  //   let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

  //   for (let i = 0; i < totalShifts; i++) {
  //     let endTime = new Date(
  //       startTime.getTime() + Number(duration) * 60 * 60 * 1000
  //     );
  //     const startTimeFormatted = startTime.toTimeString().slice(0, 5);
  //     const endTimeFormatted = endTime.toTimeString().slice(0, 5);
  //     const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
  //     shifts.push({
  //       shift_name: shiftName,
  //       start_time: startTimeFormatted,
  //       end_time: endTimeFormatted,
  //     });
  //     startTime = endTime;
  //   }
  //   setShifts(shifts);
  // };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    setIncharge(inCharge);
    const initialSelected = allTeamData?.filter(
      (staff: any) => staff.checkIn === true
    );

    const allStaff = allTeamData?.flatMap((shift: any) => shift.staff);
    setAllTeamData(allTeamData);
    setStaffCard(allTeamData);
    setSelectedValues(initialSelected);

    // Check if all items are selected based on their checked: true status
    const allChecked = AllTeamData?.every(
      (staff: any) => staff.checked === true
    );
  }, [selectedTab, allTeamData]);

  const handleSubmitRemove = async () => {
    let valid;
    if (outtimeRemove === "") {
      setOuttimeRemoveErr(true);
      valid = false;
    } else {
      setOuttimeRemoveErr(false);
      valid = true;
    }
    if (valid) {
      try {
        setLoading(true);
        const ShiftName = renderTabContent()
        const tabShiftName = ShiftName === "Previous" ? renderShiftTab() : ShiftName === "General" ? "Shift-A" : ShiftName
        const response = await axios.post(
          `${baseURL}/shiftStaff/removeStaff?date=${moment(selectedDate).format(
            "YYYYMMDD"
          )}&organization=${org}&shiftName=${tabShiftName}&staffId=${selectedRemove?.staffId
          }&outTime=${dayjs(outtimeRemove, "HH:mm").format("YYYYMMDDHHmmss")}`,
          {
            headers: header1,
          }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          setLoading(false);
          getAllTeam();
          setOpenRemoveStaff(false);
          setOuttimeRemove("");
          setOuttimeRemoveErr(false);
        }
        setLoading(false);
      } catch (error) {
        console.log("Error on remove staff", error);
        setLoading(false);
      }
    } else {
    }
  };

  const handleStaffTime = (value: any, identify: string) => {
    if (identify === "intime") {
      if (value === "") {
        setIntime(value);
        setIntimeErr(true);
      } else {
        setIntime(value);
        setIntimeErr(false);
      }
    } else if (identify === "outtime") {
      if (value === "") {
        setOuttime(value);
        setOuttimeErr(true);
      } else {
        setOuttime(value);
        setOuttimeErr(false);
      }
    } else if (identify === "removeOut") {
      if (value === "") {
        setOuttimeRemove(value);
        setOuttimeRemoveErr(true);
      } else {
        setOuttimeRemove(value);
        setOuttimeRemoveErr(false);
      }
    } else if (identify === "inChange") {
      if (value === "") {
        setIntimeChange((prev: any) => ({
          ...prev,
          value,
          error: true,
        }));
      } else {
        setIntimeChange((prev: any) => ({
          ...prev,
          value,
          error: false,
        }));
      }
    } else if (identify === "outChange") {
      if (value === "") {
        setOuttimeChange((prev: any) => ({
          ...prev,
          value,
          error: true,
        }));
      } else {
        setOuttimeChange((prev: any) => ({
          ...prev,
          value,
          error: false,
        }));
      }
    }
  };

  const handleSubmitAdd = async () => {
    let valid;

    if (intime === "") {
      setIntimeErr(true);
      valid = false;
    } else {
      setIntimeErr(false);
      valid = true;
    }

    if (outtime === "") {
      setOuttimeErr(true);
      valid = false;
    } else {
      setOuttimeErr(false);
      valid = true;
    }

    const missingObjects = AllTeamData?.filter(
      (obj1: any) =>
        !selectedValues?.some((obj2: any) => obj2.staffId === obj1.staffId)
    );

    const updatedMissingObjects = missingObjects?.map((obj: any) => ({
      ...obj,
      checkIn: false,
    }));

    const updatedSelectedObjects = selectedValues?.map((selectedObj: any) => ({
      ...selectedObj,
      checkIn: true,
      inTime: dayjs(intime, "HH:mm").format("YYYYMMDDHHmmss"),
      outTime: dayjs(outtime, "HH:mm").format("YYYYMMDDHHmmss"),
    }));

    const finalUpdatedData = [
      ...updatedMissingObjects,
      ...updatedSelectedObjects,
    ];
    if (valid) {
      try {
        setLoading(true);
        let formData = {
          date: moment(selectedDate).format("YYYYMMDD"),
          organization: org,
          shift: [
            {
              endTime:
                selectedTab === 6
                  ? shifts[0]?.end_time
                  : shifts[selectedTab]?.end_time,
              shiftIncharge: incharge,
              shiftName: renderTabContent(),
              staff: finalUpdatedData,
              startTime:
                selectedTab === 6
                  ? shifts[0]?.start_time
                  : shifts[selectedTab]?.start_time,
            },
          ],
        };
        const response = await axios.post(
          `${baseURL}/shiftStaff/register`,
          formData,
          {
            headers: header1,
          }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          setLoading(false);
          getAllTeam();
          setOpenAddStaff(false);
          setSelectedValues([]);
          setIntime("");
          setOuttime("");
          setIntimeErr(false);
          setOuttimeErr(false);
        }
        setLoading(false);
      } catch (error) {
        console.log("error on register staff ", error);
        setOpenAddStaff(false);
        setLoading(false);
        setIntime("");
        setOuttime("");
        setIntimeErr(false);
        setOuttimeErr(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleNewClose = () => {
    setOpenRemoveStaff(false);
  };
  const handleCloseAdd = () => {
    setOpenAddStaff(false);
    setIntime('')
    setOuttime('')

  };
  const handleCloseTime = () => {
    setOpenTimeChange(false);
  };
  const handleChangeTime = async () => {
    let valid;
    if (inTimeChange.value === "") {
      setIntimeChange((prev: any) => ({
        ...prev,
        value: inTimeChange?.value,
        error: true,
      }));
      valid = false;
    } else {
      setIntimeChange((prev: any) => ({
        ...prev,
        value: inTimeChange?.value,
        error: false,
      }));
      valid = true;
    }

    if (outTimeChange.value === "") {
      setOuttimeChange((prev: any) => ({
        ...prev,
        value: outTimeChange?.value,
        error: true,
      }));
      valid = false;
    } else {
      setOuttimeChange((prev: any) => ({
        ...prev,
        value: outTimeChange?.value,
        error: false,
      }));
      valid = true;
    }
    if (valid) {
      try {
        setLoading(true);
        let data = [selectedRemove];
        const updatedSelectedObjects = data?.map((selectedObj: any) => ({
          ...selectedObj,
          inTime: dayjs(inTimeChange.value, "HH:mm").format("YYYYMMDDHHmmss"),
          outTime: dayjs(outTimeChange.value, "HH:mm").format("YYYYMMDDHHmmss"),
        }));
        let formData = {
          date: moment(selectedDate).format("YYYYMMDD"),
          organization: org,
          shift: [
            {
              endTime:
                selectedTab === 6
                  ? shifts[0]?.end_time.trim()
                  : shifts[selectedTab]?.end_time.trim(),
              shiftIncharge: incharge,
              shiftName: renderTabContent(),
              staff: updatedSelectedObjects,
              startTime:
                selectedTab === 6
                  ? shifts[0]?.start_time.trim()
                  : shifts[selectedTab]?.start_time.trim(),
            },
          ],
        };
        const response = await axios.post(
          `${baseURL}/shiftStaff/register`,
          formData,
          {
            headers: header1,
          }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          setLoading(false);
          getAllTeam();
          setOpenAddStaff(false);
          setSelectedValues([]);
          setIntime("");
          setOuttime("");
          setIntimeErr(false);
          setOuttimeErr(false);
          setOuttimeChange(initialState);
          setIntimeChange(initialState);
          setOpenTimeChange(false);
        } else {
          toast.error(response.data.message.description);
          setLoading(false);

        }
      } catch (error) {
        console.log("error on register staff ", error);
        setOpenAddStaff(false);
        setLoading(false);
        setIntime("");
        setOuttime("");
        setIntimeErr(false);
        setOuttimeErr(false);
        setOuttimeChange(initialState);
        setIntimeChange(initialState);
        setOpenTimeChange(false);
      }
    } else {
      console.log("form not valid");
    }
  };
  const isAllSelected =
    AllTeamData?.length > 0 && selectedValues.length === AllTeamData?.length;
  const handleselectChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = AllTeamData?.map((data: any) => data);
      setSelectedValues(
        selectedValues.length === AllTeamData?.length ? [] : allData
      );
      return;
    }
    if (value.length === 0) {
      setSubmitStaff(true);
    } else {
      setSubmitStaff(false);
    }

    setSelectedValues(value);
  };

  const shiftAEndTime = `${endTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${endTime.getMinutes().toString().padStart(2, "0")}`;
  const BEndTime = new Date(endTime.getTime() + 8 * 60 * 60 * 1000);
  const shiftBEndTime = `${BEndTime.getHours()
    .toString()
    .padStart(2, "0")}:${BEndTime.getMinutes().toString().padStart(2, "0")}`;

  const { rnInchargeList, socialWorkerList } = useSelector(
    (state: any) => state.PSConfig
  );
  // const handleAddShifStaff = () => {
  //   let valid = false;
  //   if (incharge === "") {
  //     setInchargeErr(true);
  //     valid = false;
  //   } else {
  //     setInchargeErr(false);
  //     valid = true;
  //     // console.log(renderTabContent() === "General" ? "09:00" : renderTabContent() === "Previous" ? shifts[shifts.length - 1]?.start_time : shifts[selectedTab]?.start_time,'ASfdgyudfg')
  //     // setIntime(renderTabContent() === "General" ? "09:00" : renderTabContent() === "Previous" ? shifts[shifts.length - 1]?.start_time : shifts[selectedTab]?.start_time)
  //     // setOuttime(renderTabContent() === "General" ? "05:00" : renderTabContent() === "Previous" ? shifts[shifts.length - 1]?.end_time : shifts[selectedTab]?.end_time)
  //     const tabType = renderTabContent();
  //     const lastShift = shifts.length > 0 ? shifts[shifts.length - 1] : null;
  //     const selectedShift = shifts[selectedTab] || null;

  //     const defaultIntime = "09:00";
  //     const defaultOuttime = "05:00";

  //     // Get start_time & end_time safely
  //     const intime =
  //       tabType === "General"
  //         ? defaultIntime
  //         : tabType === "Previous"
  //           ? lastShift?.start_time || defaultIntime
  //           : selectedShift?.start_time || defaultIntime;

  //     const outtime =
  //       tabType === "General"
  //         ? defaultOuttime
  //         : tabType === "Previous"
  //           ? lastShift?.end_time || defaultOuttime
  //           : selectedShift?.end_time || defaultOuttime;

  //     console.log(intime, "Intime after validation");
  //     console.log(outtime, "Outtime after validation");

  //     setIntime(intime);
  //     setOuttime(outtime);
  //   }

  //   if (valid) {
  //     setOpenAddStaff(true);
  //     // console.log(dayjs(intime, "HH:mm", true), 'qwertyuio')
  //     // console.log(renderTabContent() === "General" ? "09:00" : renderTabContent() === "Previous" ? shifts[shifts.length - 1]?.start_time : shifts[selectedTab]?.start_time, 'qwertyuio')
  //     // Check if the intime value is valid before using dayjs
  //     if (intime) {
  //       const parsedTime = dayjs(intime, "HH:mm", true); // Ensure parsing as a time format
  //       if (parsedTime.isValid()) {
  //         console.log(parsedTime, "Valid Parsed Time");
  //       } else {
  //         console.error("Invalid Time Format for DayJS:", intime);
  //       }
  //     } else {
  //       console.error("Intime is empty or undefined");
  //     }
  //   } else {
  //     setOpenAddStaff(false);
  //   }
  // };
  const handleAddShifStaff = () => {
    let valid = false;

    if (incharge === "") {
      setInchargeErr(true);
      valid = false;
    } else {
      setInchargeErr(false);
      valid = true;
    }

    const tabType = renderTabContent();
    const lastShift = shifts.length > 0 ? shifts[shifts.length - 1] : null;
    const selectedShift = shifts[selectedTab] || null;

    const defaultIntime = "09:00";
    const defaultOuttime = "17:00";
    let intime =
      tabType === "General"
        ? defaultIntime
        : tabType === "Previous"
          ? lastShift?.start_time || defaultIntime
          : selectedShift?.start_time || defaultIntime;

    let outtime =
      tabType === "General"
        ? defaultOuttime
        : tabType === "Previous"
          ? lastShift?.end_time || defaultOuttime
          : selectedShift?.end_time || defaultOuttime;

    const formatTime = (time: string) => {
      return dayjs(time, ["H:mm", "HH:mm"]).format("HH:mm");
    };

    intime = formatTime(intime);
    outtime = formatTime(outtime);
    setIntime(intime);
    setOuttime(outtime);

    if (valid) {
      setOpenAddStaff(true);

      // Validate parsed time
      const parsedTime = dayjs(intime, "HH:mm", true);
      const parsedTime1 = dayjs(outtime, "HH:mm", true);
      if (parsedTime.isValid() && parsedTime1.isValid()) {
        setIntime(parsedTime)
        setOuttime(parsedTime1)
      } else {
        console.error("Invalid Time Format for DayJS:", intime);
      }
    } else {
      setOpenAddStaff(false);
    }
  };


  const setInchargeChange = (value: any) => {
    setIncharge(value);
    if (value === "") {
      setInchargeErr(true);
    } else {
      setInchargeErr(false);
    }
  };
  const formatTimeTo12Hour = (time: string) => {
    return moment(time, 'HH:mm').format('hh:mm A');
  };
  return (
    <div>
      {Loading && <Loader />}
      <div className="row" style={{ alignItems: "center", width: "100%" }}>
        <div className="col-md-4">
          <div style={{ fontSize: "14px" }}>

            <span>
              {renderTabContent() === "General"
                ? "09:00 AM"
                : renderTabContent() === "Previous" ? formatTimeTo12Hour(shifts[noOfShift - 1]?.start_time) : formatTimeTo12Hour(shifts[selectedTab]?.start_time)}{" "}
              {""} -
            </span>

            <span style={{ marginLeft: "5px" }}>
              {renderTabContent() === "General"
                ? "05:00 PM"
                : renderTabContent() === "Previous" ? formatTimeTo12Hour(shifts[noOfShift - 1]?.end_time) : formatTimeTo12Hour(shifts[selectedTab]?.end_time)}{" "}
              {""} {shifts[selectedTab]?.dayShift}
            </span>
          </div>
        </div>

        <div className="col-md-3">
          <Autocomplete
            sx={{ width: "200px" }}
            onChange={(e, value) => setInchargeChange(value?.id || "")}
            value={
              rnInchargeList?.find((item: any) => item?.id === incharge) || null
            }
            disablePortal
            options={rnInchargeList}
            getOptionLabel={(option) =>
              option.name[0]?.given + " " + option.name[0]?.family
            }
            renderInput={(params) => (
              <TextField
                error={inchargeErr}
                {...params}
                label="Select RN Incharge"
              />
            )}
          />
          {/* {inchargeErr && (
            <span className="form-error">Please Select Incharge</span>
          )} */}
        </div>
        <div className="col-md-3">
          <FormControl variant="outlined" fullWidth>
            <InputLabel color="primary">Staff Name</InputLabel>
            <Select
              // error={staffError}
              placeholder="Select staff"
              multiple
              sx={{ width: "200px" }}
              value={selectedValues} // This should be an array of selected values
              onChange={handleselectChange}
              color="primary"
              size="medium"
              label="Staff Name"
              name="staff_name"
              renderValue={(selectedValues) =>
                selectedValues.map((value: any) => value?.staffName).join(", ")
              }
            >
              {/* Select All / Unselect All MenuItem */}
              {/* {AllTeamData?.length > 0 ?(<> */}
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selectedValues.length > 0 &&
                      selectedValues.length < AllTeamData?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>

              {/* Render other staff options */}
              {AllTeamData?.map((newData: any, i: number) => (
                <MenuItem key={i} value={newData}>
                  <Checkbox
                    checked={selectedValues?.indexOf(newData) > -1} // Check if this item is selectedValues
                  />
                  <ListItemText primary={newData?.staffName} />
                </MenuItem>
              ))}
              {/* </>):(<>
                        <MenuItem >No Options</MenuItem>
                      </>) } */}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="col-md-1">
          <Button
            style={{ backgroundColor: "#2a509a" }}
            disabled={inchargeErr}
            onClick={() => handleAddShifStaff()}
          >
            Submit
          </Button>
        </div>
      </div>
      <hr />
      <div style={{ overflowY: "auto", height: "260px", overflowX: "hidden" }}>
        {staffCard?.length === 0 ? (
          <>
            <Typography variant="body1" className="table_empty">
              <FaInbox className="empty_svg" />
              No Staff found for this Shift
            </Typography>
          </>
        ) : (
          <div
            className="row"
            style={{ alignItems: "center", marginTop: "20px", width: "110%" }}
          >
            {staffCard?.map((data: any, index: number) => {
              return (
                <div
                  className="col-md-2 staff_card"
                  key={index}
                  title={data?.staffName}
                  style={{
                    backgroundColor:
                      data?.checkIn === true ? "white" : "#00000020",
                    cursor: "default",
                    // pointerEvents: data?.checkIn === true ? "none" : "auto",
                  }}
                >
                  <h5
                    className="selected_staff_h5"
                    style={{
                      // pointerEvents: data?.checkIn === true ? "none" : "auto",
                      cursor: "default",

                    }}

                  >
                    {data?.staffName}
                  </h5>
                  <Divider />
                  <h6 style={{ fontSize: "12px" }}>{data?.deviceId ?? 'Device Id : N/A'}</h6>
                  <h6 style={{ fontSize: "12px" }}>{data?.location ?? 'Location : N/A'}</h6>
                  <IconButton
                    sx={{ position: "absolute", top: "0", right: "0" }}
                    aria-label="Add"
                    onClick={() => handleStaffRemove(data)}
                    disabled={!data?.checkIn}
                  >
                    <MdCancel
                      style={{ color: "#ff0000a1", fontSize: "20px" }}
                    />
                  </IconButton>
                  <div
                    onClick={() => {
                      if (data?.checkIn) {
                        handleOpenTimeChange(data);
                      }
                    }}

                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "7px",
                      cursor: data?.checkIn === true ? "pointer" : "default",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <TimelineDot color="success" sx={{ margin: "0" }} />
                      </div>

                      <h6 style={{ fontSize: "11px" }}>
                        {moment(data?.inTime, "YYYYMMDDHHmmss").format("hh:mm")}
                        <span style={{ marginLeft: "2px" }}>{moment(data?.inTime, "YYYYMMDDHHmmss").format("A")}</span>
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <TimelineDot color="error" sx={{ margin: "0" }} />
                      </div>

                      <h6 style={{ fontSize: "11px" }}>
                        {data?.outTime
                          ? (
                            <>
                              {moment(data.outTime, "YYYYMMDDHHmmss").format("hh:mm")}
                              <span style={{ marginLeft: "2px" }}>
                                {moment(data.outTime, "YYYYMMDDHHmmss").format("A")}
                              </span>
                            </>
                          )
                          : "09:00"}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-md-3">
                    <TextField
                      fullWidth
                      label="Staff Name"
                      disabled
                      value={data?.staffName}
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Device ID"
                      disabled
                      value="N/A"
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Location"
                      disabled
                      value="N/A"
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <IconButton
                      aria-label="Add"
                      onClick={() => handleStaffRemove(data)}
                    >
                      <FaCircleMinus style={{ color: "#0f3995" }} />
                    </IconButton>
                  </div> */}
          </div>
        )}
      </div>
      {/* <Button
        style={{
          float: "right",
          marginTop: "20px",
          backgroundColor: "#0f3995",
        }}
      >
        Save Changes
      </Button> */}

      <Modal
        isOpen={openRemoveStaff}
        toggle={handleNewClose}
        centered
        style={{
          fontFamily: "calibri",
          fontSize: "20px",
          maxWidth: "500px",
          width: "80%",
          height: "300px",
        }}
      >
        <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
          Confirmation
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            gap: "4px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
              top: "-5px",
            }}
          >
            Are you sure you want to delete - `{selectedRemove?.staffName}`
          </div>
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            <Grid item>
              <TimePicker
                ampm={true}
                format="hh:mm A"
                label="Out Time"
                value={dayjs(outtimeRemove)}
                onChange={(newValue: any) =>
                  handleStaffTime(newValue, "removeOut")
                }
              />
            </Grid>
          </Grid>
          {outtimeRemoveErr && (
            <span className="form-error">Please Select Outtime</span>
          )}
          <div style={{ display: "flex", gap: "20px", paddingTop: "20px" }}>
            <Button color="primary" onClick={() => handleSubmitRemove()}>
              Yes
            </Button>
            <Button onClick={handleNewClose}>No</Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openAddStaff}
        toggle={handleCloseAdd}
        centered
        style={{
          fontFamily: "calibri",
          fontSize: "20px",
          maxWidth: "500px",
          width: "80%",
          height: "300px",
        }}
      >
        <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
          Add Staff for {renderTabContent()}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
              top: "-5px",
            }}
          >
            Are you sure you want to add this Staff's
          </div>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TimePicker
                ampm={true}
                format="hh:mm A"
                label="In Time"
                value={(intime !== "" && intime !== null && intime !== undefined) ? dayjs(intime, "HH:mm", true) : null}
                onChange={(newValue: any) =>
                  handleStaffTime(newValue, "intime")
                }
              />
              {intimeErr && (
                <span className="form-error">Please Select Intime</span>
              )}
            </Grid>
            <Grid item md={6}>
              <TimePicker
                ampm={true}
                format="hh:mm A"
                label="Out Time"
                value={(outtime !== "" && outtime !== null && outtime !== undefined) ? dayjs(outtime) : null}
                onChange={(newValue: any) =>
                  handleStaffTime(newValue, "outtime")
                }
              />
              {outtimeErr && (
                <span className="form-error">Please Select Outtime</span>
              )}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "5px",
              marginTop: "20px",
            }}
          >
            <Button color="primary" onClick={() => handleSubmitAdd()}>
              Yes
            </Button>
            <Button onClick={handleCloseAdd}>No</Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openTimeChange}
        toggle={handleCloseTime}
        centered
        style={{
          fontFamily: "calibri",
          fontSize: "20px",
          maxWidth: "500px",
          width: "80%",
          height: "300px",
        }}
      >
        <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
          In and Out Time change for {selectedRemove?.staffName}
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TimePicker
                ampm={true}
                format="hh:mm A"
                label="In Time"
                onChange={(newValue: any) =>
                  handleStaffTime(newValue, "inChange")
                }
                value={
                  inTimeChange?.value === " "
                    ? null
                    : dayjs(inTimeChange?.value)
                }
              />
              {inTimeChange.error && (
                <span className="form-error">Please Select Intime</span>
              )}
            </Grid>
            <Grid item md={6}>
              <TimePicker
                ampm={true}
                format="hh:mm A"
                label="Out Time"
                onChange={(newValue: any) =>
                  handleStaffTime(newValue, "outChange")
                }
                value={
                  outTimeChange?.value === " "
                    ? null
                    : dayjs(outTimeChange?.value)
                }
              />
              {outTimeChange.error && (
                <span className="form-error">Please Select Outtime</span>
              )}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button color="primary" onClick={() => handleChangeTime()}>
              Yes
            </Button>
            <Button onClick={handleCloseTime}>No</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddShiftStaff;
