import axios from "axios"
import { baseURL, successCode } from "../../configuration/url"
import { setActivityLegend, setLocationLegend } from "./reducer"

export const getLocationLegend = async (dispatch: any, org:any) => {
    try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Location%20Legend&organizationId=${org}&page=-1`)
        if (response.data.message.code === successCode) {
            dispatch(setLocationLegend(response.data.data[0].list))
        } else {
            dispatch(setLocationLegend([]))
        }
    } catch (error: any) {
        console.error("API ERROR:", error)
    }
}

export const getActivityLegend = async (dispatch: any, org:any) => {
    try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Activity%20Legend&organizationId=${org}&page=-1`)
        if (response.data.message.code === successCode) {
            dispatch(setActivityLegend(response.data.data[0].list))
        } else {
            dispatch(setActivityLegend([]))
        }
    } catch (error: any) {
        console.error("API ERROR :", error)
    }
}