import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import { baseURL } from "../../configuration/url";
import { useSelector } from 'react-redux';
import siren from "../../assets/images/siren-on1.png";
import Loader from "../../components/loader/Loader";
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FaSearch } from 'react-icons/fa';
import { Paginator } from 'primereact/paginator';

interface AlarmData {
    id: number;
    sirenName: string;
    siren: string;
    online: boolean;
}

const DeviceStatus: React.FC = () => {
    const [alarmData, setAlarmData] = useState<AlarmData[]>([]);
    const [filteredData, setFilteredData] = useState<AlarmData[]>([]);
    const [totalDevices, setTotalDevices] = useState<number>(0);
    const { organization } = useSelector((state: any) => state.Login);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState("");
    const [device, setDevice] = useState(0);
    const [DSTotalElements, setDSTotalElements] = useState(0);

    useEffect(() => {
        const getDevices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${baseURL}/siren/getAllDevices?organization=${organization}`
                );

                if (response?.data?.devices) {
                    let deviceCounter = 0;
                    const allDevices = response.data.devices.flatMap((deviceGroup: any) =>
                        deviceGroup.data.result.devices.map((device: any) => {
                            deviceCounter += 1;
                            return {
                                id: deviceCounter,
                                sirenName: device.name || 'N/A',
                                siren: device.online ? 'Online' : 'Offline',
                                online: device.online,
                            };
                        })
                    );

                    setAlarmData(allDevices);
                    setFilteredData(allDevices); // Set initial filtered data
                    const total = response.data.devices.reduce(
                        (sum: number, deviceGroup: any) => sum + deviceGroup.data.result.total,
                        0
                    );
                    setTotalDevices(total);
                    setDSTotalElements(allDevices.length);
                } else {
                    setAlarmData([]);
                    setFilteredData([]);
                    setTotalDevices(0);
                }
            } catch (error) {
                console.error('Error fetching devices:', error);
                setAlarmData([]);
                setFilteredData([]);
                setTotalDevices(0);
            } finally {
                setLoading(false);
            }
        };

        getDevices();
    }, [organization]);

    useEffect(() => {
        if (search.trim() === '') {
            setFilteredData(alarmData);
            setDSTotalElements(alarmData.length);
        } else {
            const filtered = alarmData.filter(device =>
                device.sirenName.toLowerCase().includes(search.toLowerCase()) ||
                device.id.toString().includes(search)
            );
            setFilteredData(filtered);
            setDSTotalElements(filtered.length);
        }
    }, [search, alarmData]);

    return (
        <div>
            {loading && <Loader />}
            <div className="container">
                <div className="row align-items-center justify-content-between my-2">
                    <div className="col-12 col-md-6 text-center text-md-start mb-6 mb-md-0">
                        <h2
                            style={{
                                fontWeight: 'bold',
                                color: 'black',
                                fontSize: '18px',
                                margin: 0,
                            }}
                        >
                            Device Status
                        </h2>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center gap-2">
                        <div className="search-container d-flex align-items-center">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="search form-control"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                style={{ maxWidth: '300px' }}
                            />
                            <FaSearch className="search-icon mt-1" />
                        </div>
                    </div>
                </div>
            </div>

            <Table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead>
                    <tr>
                        <th className="text-center table-data" style={{ width: '5%' }}>S.No</th>
                        <th className="text-center table-data">Name</th>
                        <th className="text-center table-data">Device Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.slice(device * 7, (device + 1) * 7).map((record) => (
                            <tr key={record.id}>
                                <td className="text-center">{record.id}</td>
                                <td className="text-center">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        {record?.sirenName && (
                                            <img
                                                src={siren}
                                                className="logo-img"
                                                style={{ width: "15px", height: "15px", marginTop: '5px', marginRight: '5px' }}
                                                alt="Siren Icon"
                                            />
                                        )}
                                        <p style={{ color: "#000", margin: 0, fontSize: '12px', paddingTop: '3px', fontWeight: 'bold' }}>
                                            {record.sirenName}
                                        </p>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className='d-flex flex-row justify-content-center'>
                                        <Tooltip title={record?.online ? "Online" : "Offline"} arrow>
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{
                                                    fontSize: "13px",
                                                    cursor: "pointer",
                                                    color: record?.online ? '#05B31C' : 'red',
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={3} className="text-center">
                                No records found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <div className="pagination-container">
                <div className="d-flex justify-content-center">
                    <Paginator
                        first={device * 7}
                        rows={7}
                        totalRecords={DSTotalElements}
                        onPageChange={(e: any) => setDevice(e.page)}
                        currentPageReportTemplate={`Page ${device + 1} of ${Math.ceil(DSTotalElements / 7)}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeviceStatus;
