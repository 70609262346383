import React, { useEffect, useRef, useState } from "react";
import './staffconfig.css'
import { useDispatch, useSelector } from "react-redux";
import { BrowserMultiFormatReader } from "@zxing/library";
import { toast } from 'react-toastify';
import axios from 'axios';
import ErrorPopup from "../../components/errorPopup";
import { closeErrorPopup, getPatientgetSuccess } from "../../slices/staffConfiguration/reducer";
import { getOrgByID } from "../../slices/organization/thunk";
import { Button } from "primereact/button";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { fetchLocationDropdown, getAllStaff, getPatientDataByDate } from "../../slices/thunk";
import { Autocomplete, Avatar, List, ListItem, MenuItem, TextField, Tooltip } from "@mui/material";
import { FaQrcode, FaSearch } from "react-icons/fa";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { primarybg, primarytext } from "../../common/primary";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { Paginator } from 'primereact/paginator'
import moment from 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { getStaffSuccess } from "../../slices/staff/reducer";
import Loader from "../../components/loader/Loader";
import DateBox from "../../components/calendar/dateBox";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const StaffConfigure = () => {

    const dispatch = useDispatch<any>()
    const { isOpen, errorMsg, AllpatientData, totalElements, loading } = useSelector((state: any) => state.PSConfig)
    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState<any>();
    const [options, setOptions] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm1, setSearchTerm1] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [deviceId, setDeviceId] = useState('')
    const { staffData, loading: staffLoading } = useSelector((state: any) => state.Staff);
    const { locationDropdown } = useSelector((state: any) => state.Dropdown);
    const [location, setLocation] = useState('');
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const [visitorModal, setVisitorModal] = useState<boolean>(false)
    const [visitorName, setVisitorName] = useState<any>('')
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [activeDetail, setActiveDetail] = useState<any>()
    const [activeMessage, setActiveMessage] = useState<any>('')
    const [ldate, setLdate] = useState<any>('')
    const [shiftDuration, setShiftDuration] = useState<any>('')
    const [Loading, setLoading] = useState<boolean>(false)
    const { shiftStartTime } = useSelector((state: any) => state.Org);

    let addSlotRegister1 = [
        {
            staff: [
                {
                    deviceId: "",
                    registeredTime: "",
                    staffId: ""
                }
            ]
        }
    ]
    const [slotRegister, setSlotRegister1] = useState(new Array<any>(addSlotRegister1));

    const closePopup = () => {
        dispatch(closeErrorPopup())
    }

    const videoRef = useRef(null);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const shiftDurationHour = [
        { 'id': '01:00', 'value': '01:00' },
        { 'id': '02:00', 'value': '02:00' },
        { 'id': '03:00', 'value': '03:00' },
        { 'id': '04:00', 'value': '04:00' },
        { 'id': '05:00', 'value': '05:00' },
        { 'id': '06:00', 'value': '06:00' },
        { 'id': '07:00', 'value': '07:00' },
        { 'id': '08:00', 'value': '08:00' }
    ]

    useEffect(() => {
        getAllStaff(dispatch, organization, -1, searchTerm ? searchTerm : '');
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate, searchTerm])

    const handlestartTime = () => {
        const crtime: any = localStorage.getItem("LTime");
        if (crtime) {
            const formattedDate = moment(crtime).format("YYYY-MM-DD");
            const currentTime = moment(crtime).format('HH:mm')
            const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
            const CurrTime: any = moment(`1970-01-01T${currentTime}`)
            if (shiftStartTime !== CurrTime) {
                if (CurrTime.isAfter(OrgStartTime)) {
                    setSelectedDate(formattedDate);
                } else {
                    if (!formattedDate) return;
                    const validDate = moment(formattedDate);
                    if (validDate.isValid()) {
                        const nextDay = validDate.clone().subtract(1, "days");
                        setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
                    } else {
                        console.error("Invalid date:", formattedDate);
                    }
                }
            } else {
                setSelectedDate(formattedDate);
            }
        }
    };
    useEffect(() => {
        // const handlestartTime = () => {
        //     const crtime: any = localStorage.getItem('LTime');
        //     if (crtime) {
        //         const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm a").format('YYYY-MM-DD');
        //         setSelectedDate(formattedDate);
        //         setLdate(moment(formattedDate).format('MM-DD-YYYY'))
        //         const formattedDate1 = moment(crtime, "MM/DD/YYYY HH:mm").toDate();
        //         setStartTime(formattedDate1);
        //     }
        // };
        handlestartTime();
    }, []);

    const handleStartTime = () => {
        const crtime: any = localStorage.getItem('LTime')
        const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm ").toDate();
        setStartTime(formattedDate);

    }

    useEffect(() => {
        handleStartTime()
        return () => {
            dispatch(getPatientgetSuccess([]))
            dispatch(getStaffSuccess([]))
        }
    }, [])
    useEffect(() => {
        if (selectedDate) {
            setLdate(moment(selectedDate).format('MM-DD-YYYY'))
        }
    }, [selectedDate])

    useEffect(() => {
        if (staffData.length === 0) {
            setVisitorModal(true)
        } else {
            setVisitorModal(false)
        }


    }, [staffData])

    const [staff, setStaff] = useState<any>(null)

    const handlecheckSOSData = (staff: any) => {
        const filterData1 = slotRegister?.find((item: any) => staff.id === item.staffId && item.deActivateTime === null)
        if (filterData1) {
            setDeviceId(filterData1?.deviceId)
            setLocation(filterData1?.location)
            setStartTime(filterData1?.startTime)
        }
    }


    const handleOpenModal = (staff: any) => {
        handlecheckSOSData(staff)
        setOpenModal(true)
        setStaff(staff)
        triggerEffectInOtherTabs()
    }
    const closeModal = () => {
        setOpenModal(false)
        setDeviceId('')
        setLocation('')
        setVisitorModal(false)
        setNameError({})
        setVisitorName('')
        setShiftDuration('')
    }
    useEffect(() => {
        if (scanning) {
            startScanning();
        } else {
            codeReader.reset();
        }

        return () => {
            codeReader.reset();
        };
    }, [scanning]);
    const handleChange = (event: any, value: any) => {
        setLocation(value);
    };
    const startScanning = async () => {
        try {
            setScanning(true);
            const videoInputDevices = await codeReader.listVideoInputDevices();
            const selectedDeviceId = videoInputDevices[0].deviceId;
            codeReader.decodeFromVideoDevice(
                selectedDeviceId,
                videoRef.current,
                (result: any, err: any) => {
                    if (result) {
                        let newDeviceId = "";
                        const result1 = result.getText();
                        if (result1 !== "" && result1 !== undefined && result1 !== null) {
                            if (result1.length < 15) {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId;
                            } else {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId.slice(6, 23);
                            }
                            setShow(false);
                            setScanning(false);
                        }
                        const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
                        if (scannedOption) {
                            setDeviceId(scannedOption.deviceId)
                            const modal = document.getElementById("exampleModal");
                            if (modal) {
                                modal.classList.add("show");
                                modal.style.display = "block";
                            }
                        } else {
                            setDeviceId("");
                            toast.error("Scanned device ID not found in the options.");
                        }
                    }
                    if (err && err.name === "NotFoundError") {
                        console.error("No QR code found in the video feed.");
                    }
                    if (err) {
                        console.error("Error during scanning:", err);
                    }
                },
            );
        } catch (error) {
            console.error("Error starting the scanner:", error);
        }
    };
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    };

    const closeModalAndRec = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    }
    const handleDateChange = (event: any) => {
        const newDate = event.target.value;
        if (newDate) {
            setSelectedDate(newDate);
        }
    };

    useEffect(() => {
        getPatientDataByDate(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, 0, searchTerm1);
    }, [selectedDate, searchTerm1]);
    useEffect(() => {
        setSlotRegister1(AllpatientData)
    }, [AllpatientData])
    const handleQrClick = () => {
        setShow(true);
        setScanning(!scanning);
    }

    useEffect(() => {
        fetchLocationDropdown(dispatch, organization);
    }, []);
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const handleConfirmModalClo = () => {
        setConfirmModal(!confirmModal)
        setConfirmMessage('')
    }

    const assignedByID = localStorage.getItem('userID')
    const triggerEffectInOtherTabs = () => {
        localStorage.setItem('ltrigger', Date.now().toString());
    };


    const handleSubmit = async (alertValue: boolean) => {
        const { header1 } = authorize();
        setLoading(true)
        let bodyData;
        if (visitorModal) {
            let newErrors = {
                deviceId: !deviceId,
                location: !location,
                visitorName: !visitorName,
                startTime: !startTime,
                duration: !shiftDuration
            }
            setNameError(newErrors)
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                toast.error("Please Fill Required Field")
                setLoading(false)
                return;
            }
            bodyData = {
                deviceId: deviceId,
                organization: organization,
                date: moment(selectedDate).format('YYYYMMDD'),
                location: location,
                assignedBy: assignedByID,
                alert: alertValue,
                singleAlert: alertValue,
                visitorName: visitorName ? visitorName : '',
                startTime: moment(startTime).format("HH:mm"),
                duration: shiftDuration
            }
        } else {
            let newErrors = {
                deviceId: !deviceId,
                location: !location,
                startTime: !startTime,
                duration: !shiftDuration
            }
            setNameError(newErrors)
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                toast.error("Please Fill Required Field")
                setLoading(false)
                return;
            }
            bodyData = {
                staffId: staff.id,
                deviceId: deviceId,
                organization: organization,
                date: moment(selectedDate).format('YYYYMMDD'),
                location: location,
                assignedBy: assignedByID,
                alert: alertValue,
                singleAlert: alertValue,
                visitorName: '',
                startTime: moment(startTime).format("HH:mm"),
                duration: shiftDuration
            }
        }
        try {
            const response = await axios.post(`${baseURL}/sosAlarm/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description);
                triggerEffectInOtherTabs()
                closeModal()
                setVisitorModal(false)
                getPatientDataByDate(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, 0, searchTerm1)
                setConfirmModal(false)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0128') {
                setConfirmMessage('The staff already has another beacon device. Do you want to remove and assign this device?')
                setConfirmModal(true)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0117') {
                setConfirmMessage('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                setConfirmModal(true)
                setLoading(false)
            } else {
                toast.error("Request failed: " + response.data.message.description);
                closeModal()
                setLoading(false)
            }
            setLoading(false)
        } catch (error: any) {
            console.error("Error config:", error.config);
            setLoading(false)
        }
    };
    const handleSearch = (e: any) => {
        setSearchTerm1(e.target.value)
    }
    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getStaffsBeacon?organization=${organization}`, { headers: header1 })
                setOptions(response.data.data);
            } catch (error) {
                setOptions([]);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [slotRegister]);


    const handleActiveModalOpen = async (organization: any) => {
        if (!organization?.deActivateTime || organization?.deActivateTime === null || organization?.deActivateTime === undefined) {
            setActiveMessage(`Deactivate ${organization?.staffName !== null ? organization?.staffName : organization?.visitorName}-${organization?.deviceId}?`);
            setActiveModal(!activeModal);
            setActiveDetail(organization);
        }
    };

    const handleAvtiveModalClo = () => {
        setActiveModal(!activeModal)
        setActiveDetail('')
        setActiveMessage('')
    }

    const handleActivatedModal = async (staff: any) => {
        setLoading(true)
        try {
            const idName = staff?.staffId ? staff?.staffId : staff?.visitorName;
            const response = await axios.post(`${baseURL}/sosAlarm/manualDeactivation?date=${staff.date}&duration=${staff?.duration}&organization=${staff?.organization}&staffId=${idName}&startTime=${staff?.startTime}`)
            if (response.data.message.code === "MHC - 0200") {
                toast.success(response.data.message.description)
                getPatientDataByDate(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, 0, searchTerm1)
                handleAvtiveModalClo()
                triggerEffectInOtherTabs()
                setLoading(false)
            } else {
                toast.error(response.data.message.description)
                setLoading(false)
            }
        } catch (error: any) {
            console.error("API Error :", error)
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className='row' style={{ overflowY: 'auto' }}>
                <div className="col-md-8 d-flex align-items-center">
                    {Loading && <Loader />}
                    {loading && <Loader />}
                    {staffLoading && <Loader />}
                    <h5>SOS Configuration</h5>
                </div>
                <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                    <div className="mx-0 search-container d-flex align-items-center" style={{ position: "relative", left: "-5px" }}>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="search form-control"
                            onChange={handleSearch}
                        />
                        <FaSearch className="search-icon mt-1" />
                    </div>
                </div>


                <div style={{ display: "flex", }} >
                    <div style={{ width: "18%", height: '87vh', backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                        <div className="row" style={{ height: '50px' }}>
                            <div className="mx-0 search-container d-flex align-items-center" >
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="search form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FaSearch
                                    className="search-icon mt-1"
                                    style={{ marginRight: '6px' }}
                                />

                            </div>
                        </div>
                        <div style={{ width: '100%', height: '90%', overflowX: 'hidden', overflowY: 'scroll' }}>
                            {staffData && staffData.length > 0 ? staffData?.map((item: any, index: any) => (
                                <div key={item.id} className="row" style={{ height: '50px', border: '1px solid grey', cursor: 'pointer' }}
                                    onClick={() => {
                                        handleStartTime()
                                        handleOpenModal(item)
                                    }}
                                >
                                    <div className="col-md-3 d-flex align-items-center justify-content-around">
                                        {
                                            item.profile && (item.profile.startsWith("http") || item.profile.startsWith("data:image")) ? (
                                                <img
                                                    src={item.profile}
                                                    alt="no image"
                                                    style={{ width: '30px', height: '30px', borderRadius: '15px' }}
                                                    onError={(e) => {
                                                        (e.target as HTMLImageElement).src = 'path/to/default/avatar.jpg';
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        objectFit: 'cover',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                    src={'path/to/default/avatar.jpg'}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="col-md-9" style={{ padding: '0' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                            <p style={{ margin: '0', marginBottom: '0px', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} title={`${item.name[0].given} ${item.name[0].family}`}> {item.name[0].given} {item.name[0].family} </p>
                                            <p style={{ margin: '0', fontSize: '13px' }}>{item.role}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                                : <div className="row" style={{ height: '70px', border: '1px solid grey', cursor: 'pointer' }}>
                                    <div className="ml-2">
                                        <p style={{ fontSize: '14px' }}>There is no record found <Button label="Create Guest" style={{ backgroundColor: primarybg }} className="" onClick={() => {
                                            setOpenModal(true)
                                            setVisitorModal(true)
                                        }} ></Button></p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ width: "81%", backgroundColor: "#f2f5f9", marginLeft: '2px' }} >
                        <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                            <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} dateRange={undefined} />
                        </div>
                        <div className="d-flex justify-content-end my-1">
                            <Button label="Create Guest" style={{ backgroundColor: primarybg }} onClick={() => {
                                setOpenModal(true)
                                setVisitorModal(true)
                            }}></Button>
                        </div>
                        <div className="row " style={{ width: '99%', marginLeft: '5px' }}>
                            <Table className="table table-bordered" style={{ fontSize: '12px' }}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '13px', color: primarytext }} className="text-center table-data">S.No</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Device ID</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Activated Time</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Valid Till</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>De-Activated Time</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Assigned By</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Active Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slotRegister?.length > 0 ? (
                                        slotRegister?.map((staff, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "left" }}>{currentPage * 8 + index + 1}</td>
                                                <td style={{ textAlign: "left" }}>{staff.staffName ? staff.staffName : staff.visitorName && staff.visitorName}</td>
                                                <td style={{ textAlign: "left" }}>{staff.deviceId}</td>
                                                <td style={{ textAlign: "left" }}>{staff.location}</td>
                                                <td style={{ textAlign: "left" }}>{staff.activateTime && moment(`${staff.activateTime.slice(0, 4)}-${staff.activateTime.slice(4, 6)}-${staff.activateTime.slice(6, 8)} ${staff.activateTime.slice(8, 10)}:${staff.activateTime.slice(10, 12)}:${staff.activateTime.slice(12, 14)}`).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                                <td style={{ textAlign: "left" }}>{staff.duration}</td>
                                                <td style={{ textAlign: "left" }}>{staff.deActivateTime && moment(`${staff.deActivateTime.slice(0, 4)}-${staff.deActivateTime.slice(4, 6)}-${staff.deActivateTime.slice(6, 8)} ${staff.deActivateTime.slice(8, 10)}:${staff.deActivateTime.slice(10, 12)}:${staff.deActivateTime.slice(12, 14)}`).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                                <td style={{ textAlign: "left" }}>{staff.assignedByName}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Tooltip
                                                        title={staff?.deActivateTime === null ? "Active" : ""}
                                                        arrow>
                                                        <div className="text-primary">
                                                            <FontAwesomeIcon
                                                                icon={faCircle}
                                                                style={{
                                                                    fontSize: "14px",
                                                                    cursor: staff?.deActivateTime !== null ? 'default' : 'pointer',
                                                                    color: staff?.deActivateTime !== null ? '#EF6868' : '#5FB477',
                                                                    display: "inline-block",
                                                                    textAlign: "center",
                                                                }}
                                                                onClick={() => {
                                                                    if (staff?.deActivateTime === null) {
                                                                        handleActiveModalOpen(staff);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Tooltip>

                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={9} className="text-center">There are no records...</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className="pagination-container" >
                            <div className="d-flex justify-content-center">
                                <Paginator
                                    first={currentPage * 10}
                                    rows={10}
                                    totalRecords={totalElements}
                                    onPageChange={(e: any) => {
                                        setCurrentPage(e.page)
                                        getPatientDataByDate(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, e.page, searchTerm1)
                                        getPatientDataByDate(dispatch, moment(selectedDate).format('YYYYMMDD'), organization, e.page, searchTerm1)
                                    }}
                                    currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={openModal} className={`${confirmModal && 'z-2'}`} style={{ transition: `${confirmModal && 'width 2s'}`, top: 45, left: '25px' }}>
                    <ModalHeader closeButton>
                        {!visitorModal ? <>
                            {staff && staff?.name[0]?.given + " " + staff?.name[0].family}

                        </>
                            : <div>Guest Register</div>
                        }
                    </ModalHeader>
                    <ModalBody>
                        <div >
                            {
                                visitorModal && <div>
                                    <div className='mb-2' >
                                        <TextField id="visitorname"
                                            label="Guest Name"
                                            variant="outlined"
                                            fullWidth
                                            value={visitorName}
                                            onChange={(e) => {
                                                setVisitorName(e.target.value)
                                                setNameError({ ...nameError, visitorName: false })
                                            }}
                                            error={!!nameError.visitorName}
                                            helperText={nameError.visitorName ? 'Guest Name is required' : ''}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className='col-md-12' style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                                        <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                                            <TextField
                                                value={deviceId}
                                                label={'Device Id'}
                                                onChange={(e) => {
                                                    setDeviceId(e.target.value)
                                                    setNameError({ ...nameError, deviceId: false })
                                                }}
                                                sx={{
                                                    border: nameError.deviceId ? '1px solid red' : 'none',
                                                    borderRadius: nameError.deviceId ? '5px' : 'none'
                                                }}
                                                style={{ minHeight: '50px', width: '96%', marginLeft: '12px', marginRight: '0px' }}

                                                InputProps={{

                                                    endAdornment: (
                                                        <FaQrcode
                                                            className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                                            role='button'
                                                            style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                                                        />
                                                    ),

                                                }}
                                                select
                                                SelectProps={{
                                                    IconComponent: () => null,
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '300px',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {options?.length > 0 ? options?.map((option: any) => (
                                                    <MenuItem key={option.id} value={option.deviceId}
                                                    >
                                                        {option.deviceId}
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            style={{
                                                                fontSize: "12px",
                                                                color: !option?.status ? '#5FB477' : '#EF6868',
                                                                display: "inline-block",
                                                                textAlign: "center",
                                                                marginLeft: '10px',
                                                                marginBottom: '1px'
                                                            }}
                                                        />
                                                    </MenuItem>
                                                )) :
                                                    <MenuItem>
                                                        No Device Data
                                                    </MenuItem>
                                                }
                                            </TextField>
                                        </ListItem>
                                    </List>
                                </div >
                                <div className='col-md-12 d-flex flex-row justify-content-between gap-1 py-3' style={{ marginRight: '0px' }}>
                                    <div className="col-md-6 " style={{}} >
                                        <DatePicker
                                            value={ldate === "" ? null : dayjs(ldate)}
                                            label={'Date'}
                                            format="MM-DD-YYYY"
                                            onChange={(e: any) => {
                                                setLdate(e.$d);
                                            }}
                                            className=""
                                            sx={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6" >
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <div style={{ width: '100%' }}>
                                                <TimePicker
                                                    label={<span>Start Time</span>}
                                                    sx={{ border: "none", borderRadius: "4px" }}
                                                    value={startTime}
                                                    onChange={(newValue: Date | null) => {
                                                        setStartTime(newValue);
                                                    }}
                                                // ampm={false}
                                                />
                                            </div>
                                        </LocalizationProvider>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <div className=" row " style={{}}>
                                    <div className="col-md-6">
                                        <Autocomplete
                                            id={'organizationDetails'}
                                            options={locationDropdown?.map((item: any) => item.value)}
                                            value={location}
                                            onChange={(e, v) => {
                                                handleChange(e, v)
                                                setNameError({ ...nameError, location: false })
                                            }}
                                            renderInput={(params) => <TextField {...params} label={'Location'} variant="outlined"
                                                error={!!nameError.location}
                                                helperText={nameError.location ? 'Location is required' : ''}
                                            />}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Autocomplete
                                            id={'shiftDuration'}
                                            options={shiftDurationHour?.map((item: any) => item.value)}
                                            value={shiftDuration}
                                            onChange={(e: any, v) => {
                                                setShiftDuration(v)
                                                setNameError({ ...nameError, duration: false })
                                            }}
                                            renderInput={(params) => <TextField {...params} label={'Duration'} variant="outlined"
                                                error={!!nameError.duration}
                                                helperText={nameError.duration ? 'Duration is required' : ''}
                                            />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ gap: '4px' }}>
                        <Button label={'Close'} variant="secondary" style={{ backgroundColor: '#94a0b7', fontSize: '12px' }} onClick={closeModal} >
                        </Button>
                        <Button label={'Save Changes'} style={{ backgroundColor: '#0f3995', fontSize: '12px' }} onClick={() => handleSubmit(false)} />
                    </ModalFooter>
                </Modal>
                <Modal isOpen={show} >
                    <ModalHeader closeButton>
                        Scanning
                    </ModalHeader>

                    <ModalBody>
                        <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            label={'Close'}
                            variant="secondary"
                            onClick={handleClose}
                            style={{ marginRight: '10px' }}
                        />
                        <Button
                            label={scanning ? "Stop Scanning" : "Start Scanning"}
                            style={{ backgroundColor: '#0f3995' }}
                            onClick={closeModalAndRec}
                        />
                    </ModalFooter>

                </Modal>
                <ErrorPopup
                    closePopup={closePopup}
                    errorMsg={errorMsg}
                    open={isOpen}
                />
                <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
                <LogoutConfirmationModal open={confirmModal} handleConfirm={() => handleSubmit(true)} handleClose={() => handleConfirmModalClo()} message={confirmMessage} />
            </div>
        </React.Fragment>
    );
};

export default StaffConfigure;